import React from 'react'
import { Row, Col, Image, Form, Button } from 'react-bootstrap'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Card from '../../../components/Card'

// img
import avatars1 from '../../../assets/images/avatars/01.png'
import auth1 from '../../../assets/images/auth/04.png'
import ResetPasswordForm from "../../../components/reset_password_form";
import {getQuery} from "../../../store/helpers/common_functions";

const LockScreen = () => {

   // const {token,email} = useParams();
   const token = getQuery("token");
   const email = getQuery("email");
   return (
       <ResetPasswordForm inline={true} token={token} email={email}/>
   )
}

export default LockScreen
