import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {connect} from 'react-redux';
import {Col, Row} from "react-bootstrap";
import AddForm from "../../../helpers/AddForm";
import {downloadBase64} from "../../../store/helpers/common_functions";
import React, {useEffect, useState} from "react";
import {languages_const} from "./ocr_scan";
import {Button, CircularProgress, Typography} from "@mui/material";
import {ArrowForwardSharp, FileCopy, Splitscreen} from "@mui/icons-material";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import DeleteDialog from "../../../helpers/DeleteDialog";

const OcrScanUploadPDF = (props) => {
  const {onSuccess,SubmitGeneralForm,section,newId} = props;
  const [languages] = React.useState(languages_const);
  const [openHyper, setOpenHyper] = useState(0);

  const [sWidth, setSWidth] = useState(-80);
  const [sHeight, setSHeight] = useState(-120);
  const [tWidth, setTWidth] = useState(-45);
  const [tHeight, setTHeight] = useState(-65);
  const [file, setFile] = useState(null);
  const [baseFile, setBaseFile] = useState(null);
  const [loading,setLoading] = useState(false);
  const [base64,setBase64] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(0);
  const [log, setLog] = useState(null);
  const [references, setReferences] = useState([]);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [fileId, setFileId] = useState(null);
  const [fileContent, setFileContent] = useState(null);



  const downloadBase = async ()=>{
    setLoading(true);
    setBase64(null);
    const base = await downloadBase64("files/download",{
      path:file.path
    },"application/pdf");
    setLoading(false);
    if(base) {
      setBase64(base);
    }
  }

  const ocrScan = async ()=>{
    setScanning(true);
    const data = new FormData();
    data.append("path",file.path);
    data.append("lang","en");
    data.append("section",section);
    const resp = await SubmitGeneralForm("ocr/scan",data);
    setScanning(false);
    if(resp.status){
      setLog(resp.data.data.id);
      setReferences(resp.data.data.references);
      setOpenSubmit(1);
    }
  }


  useEffect(() => {
    if(file !== null){
      downloadBase().then();
    }
  }, [file]);


  return <div>
    <Row>
      <Col md={5}>
        <DeleteDialog usePost={true} setOpen={(val)=>{
          setScanning(val);
          if(!val && typeof onSuccess === 'function'){
            onSuccess(null);
          }
        }} open={openHyper}  title={'Re-HyperLink Document'} body={{background:fileContent?.size > 10000000}} info={true} bodyText={'Are you sure you want to re-hyperlink  document'} url={`documents-files/re-hyperlink/${fileId}`} {...props}/>

        <AddForm onSuccess={(data)=>{
          setOpenHyper(1);
          console.log(data);
          setFileId(data?.data?.id);
        }} title={'Create Document'} url={'documents-files/create'} open={openSubmit} setOpen={()=>setOpenSubmit(0)} mainFields={[
          {
            title:"",
            fields:[
              {
                name: "languages",
                label: "Select Language",
                submitValue:(val)=>JSON.parse(val)?.join(", "),
                values: languages,
                type: 'multi-select',
                required: true
              },
              {
                name:"file",
                value:file,
                hidden:true
              },
              {
                name:"log",
                value:log,
                hidden:true,
              },
              {
                name:"references",
                value:references,
                hidden:true,
                submitValue:(val)=>val.join(", ")
              },
              {
                name:"document",
                value:newId,
                hidden:true
              }
            ]
          }
        ]}/>
        {file === null && <AddForm inline={true} setOpen={(_,data) => {

          if(typeof onSuccess === 'function' && !data){
            onSuccess(null);
          }
          return false;
        }} method={'POST'} title={'Upload Document'} url={'files/upload'}
                  mainFields={[
                    {
                      title: "",
                      fields: [

                        {
                          name: "file1",
                          value:fileContent,
                          setValue:setFileContent,
                          label: "Upload PDF Document",
                          urlParam: true,
                          type: 'file-drag',
                          required: true,
                          fileTypes: ["PDF"],
                        },
                      ]
                    }
                  ]} onSuccess={(res) => {
                    const f = res.data[0];
                    setFile(f);
                    setBaseFile(f);
        }}/>}
        {file !== null && <>
          <AddForm cancelLabel={'Reset Cropping'} inline={true} setOpen={(open,data) => {
            if(!data){
              setFile(baseFile);
            }
            return false;
          }} method={'POST'} title={'Crop Uploaded Document'}
                   url={`pdf/crop-new?filePath=${file?.path}&fileName=${file?.name}`}
                   mainFields={[
                     {
                       title: "",
                       fields: [
                         {
                           name: "sWidth",
                           value: sWidth,
                           setValue: setSWidth,
                           urlParam: true,
                           col: 6,
                           label: "Right"
                         },
                         {
                           name: "sHeight",
                           value: sHeight,
                           urlParam: true,
                           setValue: setSHeight,
                           col: 6,
                           label: "Top",
                         },
                         {
                           name: "tWidth",
                           value: tWidth,
                           urlParam: true,
                           col: 6,
                           label: "Left",
                           setValue: setTWidth,
                         },
                         {
                           name: "tHeight",
                           label: "Bottom",
                           urlParam: true,
                           col: 6,
                           value: tHeight,
                           setValue: setTHeight,
                         },
                       ]
                     }
                   ]} onSuccess={(res) => {

            setFile(res.data[0]);
          }} buttonTitle={'Crop'}/>
          <div className={'text-center'}>
            <div>
              <Button variant={'contained'} onClick={ocrScan} disabled={scanning} startIcon={scanning ? <CircularProgress size={25}/> : <ArrowForwardSharp/>}>Confirm And Continue</Button>
            </div>
          </div>
        </>
        }
      </Col>
      <Col md={7}>
        {base64 !== null ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl={base64}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                  ]}/>
        </Worker>  : <div className={'d-flex justify-content-center align-items-center'} style={{minHeight: "75vh"}}>
          <div className={'text-center'} style={{color: "#bdbdbd"}}>
            {loading ? <CircularProgress/> : <>
              <FileCopy style={{fontSize: "100px"}}/>
              <div className={'mt-2'}>
                <Typography variant={'h3'} color={'inherit'}>PDF Preview</Typography>
              </div>
            </>}
          </div>
        </div>}
      </Col>
    </Row>
  </div>;
}


export default connect(mapStateToProps,mapDispatchToProps)(OcrScanUploadPDF);