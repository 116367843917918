
import React, {useEffect, useState} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {
    Block,
    BorderColor,
    Lock,
    VerifiedUser
} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import DeleteDialog from "../../../helpers/DeleteDialog";
import {inArray} from "../../../store/helpers/common_functions";
const Visitors = (props) => {
    const {FetchDetails,loggedUser} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const [institutions, setInstitutions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [openReset, setOpenReset] = useState(false);
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }



    const loadInstitutions = async ()=>{
        const resp = await FetchDetails("dropdowns/institutions?language=en");
        if(resp.status){
            setInstitutions(resp.data.data);
        }
    }

    const loadRoles = async ()=>{
        const resp = await FetchDetails("dropdowns/roles");
        if(resp.status){
            setRoles(resp.data.data);
        }
    }

    useEffect(()=>{
        loadInstitutions().then();
        loadRoles().then();
    },[FetchDetails]);



    return <>

        <AddForm setOpen={handleClose} method={id ? 'PUT' : 'POST'} title={id ? 'Update User' : 'Create New User'} url={id ? `users/update/${id}` : 'users/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name",
                        label:"Name",
                        required:true,
                        value:info?.name,
                    },
                    {
                        name:"email",
                        label:"Email",
                        type:'email',
                        value:info?.email,
                        required:true
                    },
                    {
                        name:"phone_number",
                        label:"Phone Number",
                        type:'tel',
                        value:info?.phone_number,
                        required:true
                    },
                    // {
                    //     name:"position",
                    //     label:"Position",
                    //     type:'text',
                    //     value:info?.position,
                    //     required:false
                    // },
                    {
                        name:"institution",
                        label:"Select Institution",
                        type:'select',
                        value:info?.institution?.id,
                        values: institutions.map(v=>({label:v.label,id:v.value})),
                        required:true,
                    },
                    {
                        name:"role",
                        label:"Select Role",
                        type:'select',
                        value:info?.role?.id,
                        values: roles.map(v=>({label:v.label,id:v.value})),
                        required:true,
                    },
                    {
                        name:"send_mail",
                        value:false,
                        hidden:true
                    }
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DeleteDialog url={'auth/reset-password-admin'} usePost={true} open={openReset} body={{
            id
        }} title={`Reset this user's password ?`} buttonTitle={'RESET'} bodyText={`Are you sure you want to reset this user's password ?`} {...props} setOpen={setOpenReset}/>
        <DatatableComponent
        //     deleteUrl={inArray(loggedUser?.permissions,"users-delete") ? (id,info)=>({
        //     url:`users/action/single`,
        //     post:true,
        //     icon:()=>{
        //         return info?.status === 3 ? <VerifiedUser /> : <Block/>
        //     },
        //     color:info?.status === 3 ? 'success' : undefined,
        //     title:info?.status === 3 ? 'activate' : 'deactivate',
        //     body:{
        //         id,
        //         action:info?.status === 3 ? "active" : "block"
        //     }
        // }) : undefined}
            addRowNumber={true}
        //     onAddNew={inArray(loggedUser?.permissions,"users-create") ? ()=>{
        //     setOpen(1);
        // } : undefined}
            elevation={1} columns={[
            {
                label:"Ip Address",
                name:"ip"
            },
            {
                label:"Country",
                name:"co"
            },
            {
                label:"Updated At",
                name:"updated_t",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Visitors List'} url={`visitors/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(Visitors);
