import {Chip} from "@mui/material";
import {inArray} from "../../store/helpers/common_functions";
import PublishStatus from "./publish_status";

const Status = ({status,size,info}) => {
  if(info?.status_publish !== 0){
    return <PublishStatus status={info?.status_publish}/>
  }
  return <Chip size={size??'small'} color={inArray([2,4],status) ? 'warning' : status === 3 ? 'error' : status === 0 ? 'secondary' : 'default'} label={status === 0 ? "Draft" : status === 1 ? "Pending for review" : status === 2 ? "Approved pending for publication" : status === 3 ? "Action requested" : "---"}/>;
}

export default Status;