import {Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";

const ListTile = (props) => {
    const {selected,item,handleToggle,disabled} = props;


    return (<ListItem
        disablePadding
    >
        <ListItemButton role={undefined}
                        disabled={disabled}
                        onClick={()=>handleToggle(item)}
                        dense>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={selected}
                    tabIndex={-1}
                    disabled={disabled}
                    onClick={(event)=>{
                        event.stopPropagation();
                        handleToggle(item)
                    }}
                    disableRipple
                    // inputProps={{ 'aria-labelledby': labelId }}
                />

            </ListItemIcon>
            <ListItemText primary={item.label} secondary={item.secondary} />
        </ListItemButton>
    </ListItem>);
}


export default ListTile;