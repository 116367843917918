import { bindActionCreators } from "redux";
import {
    deleteAction,
    getTechnologies,
    submitGeneralForm,
    systemLogin,
    fetchDetails,
    putAction, getLoggedUser, sendResetEmail, resetPassword, downloadPostBase64
} from "../User/action";


export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      SystemLogin: systemLogin,
      SendResetEmail: sendResetEmail,
      ResetPasswordAction: resetPassword,
      SubmitGeneralForm: submitGeneralForm,
      GetLoggedUser: getLoggedUser,
      DeleteAction: deleteAction,
      PutAction: putAction,
      FetchDetails: fetchDetails,
      GetTechnologies: getTechnologies,
      DownloadPostBase64: downloadPostBase64,

    },
    dispatch
  );
};
