import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import * as React from 'react';
import { toast } from 'react-toastify';
import {getError, getSuccessMessage} from "../store/helpers/common_functions";
// import {submitGeneralForm} from "../store/User/action";
// import {JSX} from "react";
// import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
// import {successToast} from "./Notification";

// type IProps = {
//   open: number;
//   setOpen: (i: number) => void;
//   onSuccess?: (res: any) => void;
//   url: string;
//   info?: boolean;
//   title?: string | undefined;
//   bodyText?: string | ReactJSXElement | undefined;
//   maxWidth?: Breakpoint | undefined;
//   body?: any | undefined;
//   buttonTitle?: string | undefined;
//   usePost?: boolean | undefined;
//   fullWidth?: boolean | undefined;
// };

const DeleteDialog = (props) => {
  const {
    open,
    setOpen,
    url,
    onSuccess,
    info,
    title,
    bodyText,
    maxWidth,
    body,
    buttonTitle,
    usePost,
    PutAction,
    SubmitGeneralForm,
    DeleteAction,
      fullWidth
  } = props;
  const [deleting, setDeleting] = React.useState(false);
  const [error, setError] = React.useState();

  const handleClose = () => {
    setError(null);
    setOpen(0);
  };

  return (
    <Dialog open={open > 0} maxWidth={maxWidth ?? 'xs'} fullWidth={fullWidth} onClose={handleClose}>
      <div className={'position-relative'}>
      {deleting ? (
        <div
          className={'position-absolute h-100 w-100 top-0 start-0 d-flex z-50 justify-content-center align-items-center'}
          style={{ backgroundColor: info ? 'rgba(83,220,85,0.2)' : 'rgba(220,83,83,0.2)' }}
        >
          <div className={'bg-white p-3 rounded shadow text-center'} style={{ zIndex: 20000 }}>
            <div>
              <CircularProgress color={info ? 'success' : 'error'} />
            </div>
            <span>
              <Typography color={info ? 'success' : 'error'}>
                {buttonTitle ? 'Loading' : info ? 'Submitting' : 'Deleting'} ....
              </Typography>
            </span>
          </div>
        </div>
      ) : null}
      <DialogTitle><span className={'text-capitalize'}>{title ?? (info ? 'Action' : 'Delete')}</span></DialogTitle>
      <DialogContent>
        {error ? (
          <Alert
            style={{ width: '100%' }}
            className={'mb-3'}
            severity={'error'}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        ) : null}
        <div className={'flex items-center'}>
          <DialogContentText>
            {bodyText ?? (info ? 'Are you sure ?' : `Are you sure, you want to ${buttonTitle ?? 'delete'} this item?`)}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={async () => {
            setDeleting(true);
            try {
              const resp = usePost
                ? await SubmitGeneralForm(url, body)
                : info
                ? await PutAction(url, body)
                : await DeleteAction(url);
              if (resp.status) {
                handleClose();
                toast.success(getSuccessMessage(resp));
                if (onSuccess && typeof onSuccess === 'function') {
                  onSuccess(resp);
                }
              } else {
                setError(resp?.data?.data?.message ?? 'Error Found !!');
              }
            } catch (e) {
              setError(getError(e));
            }
            setDeleting(false);
          }}
          variant={'contained'}
          color={info ? 'success' : 'error'}
        >
          {buttonTitle ?? (info ? 'Next' : 'Delete')}
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
