
import React, {useEffect} from 'react'
import DatatableComponent from "../../../../helpers/DatatableComponent";
import moment from "moment";
import {Accessibility, BorderColor, RemoveRedEye} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {useNavigate} from "react-router-dom";
import Status from "../../../../components/partials/status";
import {defaultFilters, inArray} from "../../../../store/helpers/common_functions";
import SubmitApplication from "../../../../components/partials/submit_application";
const ScholarlyMaterialsDocuments = (props) => {
    const {loggedUser} = props;
    const [change,setChange] = React.useState(0);
    const navigate = useNavigate();

    return <>
        <DatatableComponent filters={defaultFilters} titleClass={'p-0'} deleteUrl={inArray(loggedUser?.permissions,"documents-delete") ?(id)=>({
            url:`documents/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"documents-create") ?()=>{
            navigate('/dashboard/scholar-material/create');
        } : undefined} actions={(id,_meta,data)=>[
            <IconButton onClick={() => {
                navigate(`/dashboard/scholar-material/details/${id}`);
            }}>
                <RemoveRedEye />
            </IconButton>,
            (data?.status_publish === 3 || inArray([0,3],data?.status)) && inArray(loggedUser?.permissions,"documents-update") && <IconButton onClick={() => {
                navigate(`/dashboard/scholar-material/update/${id}`);
            }}>
                <BorderColor />
            </IconButton>,
            inArray([0,1],data?.status) && <SubmitApplication {...props} info={data} size={'small'} onSuccess={()=>setChange(v=>v+1)}/>

        ]} elevation={0} columns={[
            {
                label:"Name",
                name:"name"
            },
            {
                label:"Collection",
                name:"collection.name_en"
            },
            {
                label:"Date",
                name:"date",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            },
            {
                label:"Status",
                name:"status",
                options:{
                    customBodyRender:(v,_meta,info)=><Status status={v} info={info}/>
                }
            }
        ]} title={'Scholarly Materials Documents'} url={`documents/table?change=${change}&section=2.2.3`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(ScholarlyMaterialsDocuments);
