
import React, {useEffect, useState} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Block, BorderColor, VerifiedUser} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {inArray} from "../../../store/helpers/common_functions";
const Roles = (props) => {
    const {FetchDetails,loggedUser} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const [permissions, setPermissions] = React.useState([]);
    const [selectedPermissions, setSelectedPermissions] = React.useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [institutionId, setInstitutionId] = useState()
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
        setSelectedPermissions([]);
    }


    const loadInstitutions = async ()=>{
        const resp = await FetchDetails("dropdowns/institutions?language=en");
        if(resp.status){
            if(loggedUser?.institution?.id){
                setInstitutions(resp.data.data?.filter(v=>v.value === loggedUser.institution.id));
            }else {
                setInstitutions(resp.data.data);
            }
        }
    }

    const loadInstitutionView = async ()=>{
        const resp0 = await FetchDetails("dropdowns/permissions?language=en");
        const resp = await FetchDetails(`institutions/${institutionId}`);
        if(resp.status && resp0.status){
            let info = resp.data.data;
            let info0 = resp0.data.data;
            setInfo(info);
            if(info.permissions) {
                setPermissions(JSON.parse(info.permissions).map(v=>({label:info0?.find(it=>it.value === v)?.label ?? v,value:v})));
            }else{
                setPermissions([]);
            }
        }
    }

    useEffect(()=>{
        if(institutionId) {
            loadInstitutionView().then();
        }
    },[institutionId]);

    useEffect(()=>{
        loadInstitutions().then();
    },[FetchDetails]);


    return <>
        <AddForm maxWidth={'lg'} col={6} setOpen={handleClose} method={id ? 'PUT' : 'POST'} title={id ? 'Update Role' : 'Create New Role'} url={id ? `roles/update/${id}` : 'roles/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name",
                        label:"Name",
                        required:true,
                        value:info?.name,
                    },
                    {
                        name:"institution",
                        label:"Select Institution",
                        type:'select',
                        value:institutionId,
                        values: institutions.map(v=>({label:v.label,id:v.value})),
                        setValue:setInstitutionId,
                        required:true,
                    },
                    {
                        name: "permissions",
                        label: "Permissions",
                        col:12,
                        value: selectedPermissions,
                        values: permissions.map(v=>({label:v.label,value:v.value})),
                        type: 'checkbox',
                        // required: true,
                        submitAsString:true,
                        checkCol:4,
                    }
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
           <DatatableComponent forceDeleteUrl={(id )=>({
               url:`roles/action/single`,
               post:true,
               body:{
                   id,
                   action:"delete"
               }
           })} deleteUrl={inArray(loggedUser?.permissions,"roles-delete") ? (id,info)=>({
            url:`roles/action/single`,
               post:true,
               icon:()=>{
                   return info?.status === 0 ? <VerifiedUser /> : <Block/>
               },
               color:info?.status === 0 ? 'success' : undefined,
               title:info?.status === 0 ? 'activate' : 'deactivate',
               body:{
                   id,
                   action:info?.status === 0 ? "active" : "block"
               }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"roles-create") ? ()=>{
            setOpen(1);
        } : undefined} actions={(id, _meta,info)=>[
               inArray(loggedUser?.permissions,"roles-update") && <IconButton onClick={() => {
                setOpen(2);
                setInfo(info);
                setId(id);
                   setInstitutionId(info?.institution?.id ?? info?.institution_id);
                setSelectedPermissions(info.permissions ? JSON.parse(info.permissions) : [])
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"Name",
                name:"name"
            },
            {
                label:"Institution",
                name:"institution.name_en"
            },
               {
                   label:"Status",
                   name:"status",
                   options: {
                       customBodyRender:(v)=><Chip size={'small'} color={v=== 0 ? 'error' : 'success'} label={v === 0 ? 'Disabled' : 'Active'}/>
                   }
               },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Roles List'} url={`roles/table?change=${change}${loggedUser?.institution?.id ? `&institution_id=${loggedUser.institution.id}` : ''}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(Roles);
