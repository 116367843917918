import {Button, CircularProgress, IconButton} from "@mui/material";
import {CancelOutlined, CheckCircleRounded, Send} from "@mui/icons-material";
import {useState} from "react";
import Swal from "sweetalert2";
import {inArray} from "../../store/helpers/common_functions";

const PublishApplication = ({size,info,SubmitGeneralForm,onSuccess,loggedUser}) => {


    const canApprove = inArray(loggedUser?.permissions,'documents-review');

    const [loading, setLoading] = useState(false);

    const onSubmit = async (action)=>{
        setLoading(true);
        const resp = await SubmitGeneralForm("documents/action/single",{
         id:info?.id,
         action,
        });
        if(resp.status && typeof onSuccess === 'function'){
            onSuccess(resp.data);
        }
        setLoading(false);
    }

    const onClick = (action)=>{

        Swal.fire({
            title: action === 'public' ? "Publish Document" : (action === 'unpublish' ? 'Unpublish Document' : 'Put Document on Hold'),
            text: action === 'public' ? `Are you sure you want to publish this document ?` : (action === 'unpublish' ? 'Are you sure you want to unpublish this document ?' : 'Are you sure you want to put this document on hold?'),
            icon: 'success',
            showConfirmButton:true,
            showCancelButton:true,
            confirmButtonColor: canApprove ? '#2e7d32' : '#1976d2',
            confirmButtonText: "Submit",
        }).then((info)=>{

            // console.log(info.value);
            if(info.isConfirmed){
                onSubmit(action).then();
            }
        });
    }

    const icon = loading ? <CircularProgress size={30}/> : (canApprove ? <CheckCircleRounded/> : <Send/>);

    const color = canApprove ? 'success' : 'primary';

  return canApprove && <>
      <div className={'d-inline-block text-center mt-2'}>
          {info?.status_publish !== 0 && info?.status_publish !== 3 && <Button size={'large'} variant={'contained'} color={'info'} disabled={loading} onClick={()=>onClick('unpublish')}
                   startIcon={icon}>Unpublish Document</Button>}
          {info?.status_publish !== 1 && <Button size={'large'} className={'ms-2'} variant={'contained'} color={color} disabled={loading}
                   onClick={() => onClick('public')} startIcon={icon}>Publish Document</Button>}
          {info?.status_publish !== 2 && <Button size={'large'} className={'ms-2'} variant={'contained'} color={'secondary'} disabled={loading} onClick={() => onClick('hold')}
                   startIcon={icon}>Hold Document</Button>}
      </div>
  </>;
}

export default PublishApplication;