import {Form} from "react-bootstrap";
import React, {useState} from "react";

const PasswordField = (props) => {
    const {id,placeholder} = props;
    const [show, setShow] = useState(false);
  return <div className={'position-relative'}>

      <div className={'position-absolute top-0 py-2 px-3'} style={{left:0}}>
          <i className={'fa fa-lock'}></i>
      </div>
      <div className={'position-absolute top-0 py-2 px-3'} onClick={()=>setShow(show=>!show)} style={{right:0}}>
          <i className={show ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
      </div>
      <input type={show ? 'text' : "password"} id={id} placeholder={placeholder} {...props}  className={'ps-5 form-control rounded-0'}/>
  </div>
}

export default PasswordField;