import React, {useEffect, memo, Fragment, useState} from "react";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//circular
import Circularprogressbar from "../../components/circularprogressbar.js";

// AOS
import AOS from "aos";
import "../../../node_modules/aos/dist/aos";
import "../../../node_modules/aos/dist/aos.css";
//apexcharts
import Chart from "react-apexcharts";

import {connect} from 'react-redux';
import sectionsJson from "../dashboard/admin/sections.json";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

//Count-up
import CountUp from "react-countup";

// Redux Selector / Action
import { useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";
import {mapStateToProps} from "../../store/helpers/mapState";
import {mapDispatchToProps} from "../../store/helpers/mapDispatch";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {DialogTitle} from "@mui/material";

SwiperCore.use([Navigation]);

const Index = ({FetchDetails}) => {
  useSelector(SettingSelector.theme_color);

  const [recent, setRecent] = useState([]);
  const [active, setActive] = useState(null);
  const [visitors, setVisitors] = useState(null);
  const [floors, setFloors] = useState([
      {
        label:"Documents count by section",
  },
    {
      label: "Documents count by Categories",
    },
    {
      label: "Documents count by Collections",
    },
    {
      label: "Documents count by Institutions",
    },
  ]);

  const loadRecent = async ()=>{
    const resp = await FetchDetails("documents/table-latest?start=0&length=10");
    if(resp.status){
      setRecent(resp.data.data.data);
    }
  }

  const loadSections = async ()=>{
    const resp = await FetchDetails("dashboard/sections");
    if(resp.status){
      setFloors(floors=>{
        const data = resp.data.data;
        floors[0].data = data.map(v=>({...v,name:all.find(value => value.id === v.name)?.name_en ?? v.name}));
        return [...floors];
      })
    }
  }
  const loadCategories = async ()=>{
    const resp = await FetchDetails("dashboard/categories");
    if(resp.status){
      setFloors(floors=>{
        floors[1].data = resp.data.data;
        return [...floors];
      })
    }
  }
  const loadCollections = async ()=>{
    const resp = await FetchDetails("dashboard/collections");
    if(resp.status){
      setFloors(floors=>{
        floors[2].data = resp.data.data;
        return [...floors];
      })
    }
  }
  const loadInstitutions = async ()=>{
    const resp = await FetchDetails("dashboard/institutions");
    if(resp.status){
      setFloors(floors=>{
        floors[3].data = resp.data.data;
        return [...floors];
      })
    }
  }
  const loadVisitors = async ()=>{
    const resp = await FetchDetails("dashboard/visitors");
    if(resp.status){
      setVisitors(resp.data.data);
    }
  }

  useEffect(() => {
    loadRecent().then();
    loadSections().then();
    loadCategories().then();
    loadCollections().then();
    loadInstitutions().then();
    loadVisitors().then();
  }, [FetchDetails]);

  const all = [];

  const extractSections = (children)=>{
    for (let item of children){
      if(item.children){
        extractSections(item.children);
      }else{
        all.push(item);
      }
    }
  }

  extractSections(sectionsJson);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();

  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });

  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });
  });

  return (
    <Fragment>
      <Dialog open={active !== null} maxWidth={'md'} fullWidth={true} onClose={()=>setActive(null)}>
        {active !== null && <>
          <DialogTitle>{active.name}</DialogTitle>
          <DialogContent>
            <div className={'row'}>
              {[
                {
                  name:"count_draft",
                  label:"Draft"
                },
                {
                  name:"count_review",
                  label:"Under Review"
                },
                {
                  name:"count_approved",
                  label:"Approved"
                },
                {
                  name:"count_rejected",
                  label:"Rejected"
                },
                {
                  name:"count_private",
                  label:"Private"
                },
                {
                  name:"count_public",
                  label:"Public"
                },
                {
                  name:"count_hold",
                  label:"On Hold"
                },
                {
                  name:"count_unpublished",
                  label:"Unpublished"
                },
                {
                  name:"count_language_en",
                  label:"English Docs"
                },
                {
                  name:"count_language_rw",
                  label:"Kinyarwanda Docs"
                },
                {
                  name:"count_language_fr",
                  label:"French Docs"
                },
              ].map(el=><div className={'col-md-6'}><div className="card card-slide mb-3 shadow-sm">
                <div className="card-body">
                  <div className="progress-widget">
                    <Circularprogressbar
                        stroke={variableColors.primary}
                        width="60px"
                        height="60px"
                        Linecap="rounded"
                        trailstroke="#ddd"
                        strokewidth="4px"
                        style={{ width: 60, height: 60 }}
                        value={90}
                        id="circle-progress-01"
                    >
                      <svg
                          className=""
                          width="24"
                          height="24px"
                          viewBox="0 0 24 24"
                      >
                        <path
                            fill="currentColor"
                            d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                        />
                      </svg>
                    </Circularprogressbar>
                    <div className="progress-detail">
                      <p className="mb-2">{el.label}</p>
                      <h4 className="counter">
                        <CountUp separator={","} start={0} end={active.counts[el.name]} duration={3} />
                      </h4>
                    </div>
                  </div>
                </div>
              </div></div>)}
            </div>
          </DialogContent>
        </>}
      </Dialog>
      <Row>
        <Col md="12" lg="12">
          {floors.filter(v=>v.data?.length>0).map((floor,index)=><Row className="row-cols-1">
            <div data-aos="fade-up" data-aos-delay="800">

              <div className={'mb-2'}><h4 style={{color: index ===0 ? '#ffffff' : 'inherit'}}>{floor?.label}</h4></div>
              <div className="overflow-hidden d-slider1 ">
                <Swiper
                    className="p-0 m-0 mb-0 list-inline "
                    slidesPerView={5}
                    spaceBetween={32}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 3 },
                      1400: { slidesPerView: 3 },
                      1500: { slidesPerView: 4 },
                      1920: { slidesPerView: 4 },
                      2040: { slidesPerView: 7 },
                      2440: { slidesPerView: 8 }
                    }}

                >
                  {floor.data?.map(el=><SwiperSlide className="card card-slide mb-3">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                            stroke={variableColors.primary}
                            width="60px"
                            height="60px"
                            Linecap="rounded"
                            trailstroke="#ddd"
                            strokewidth="4px"
                            style={{ width: 60, height: 60 }}
                            value={90}
                            id="circle-progress-01"
                        >
                          <svg
                              className=""
                              width="24"
                              height="24px"
                              viewBox="0 0 24 24"
                          >
                            <path
                                fill="currentColor"
                                d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">{el.name}</p>
                          <h4 className="counter">
                            <CountUp separator={","} start={0} end={el.counts.count} duration={3} />
                            <Button onClick={()=>{
                              setActive(el);
                            }} size={'sm'} className={'ms-2 p-0 mb-1'} variant={'link'}>More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                            </svg></Button>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>)}

                  <div className="swiper-button swiper-button-next"></div>
                  <div className="swiper-button swiper-button-prev"></div>
                </Swiper>
              </div>
            </div>
          </Row>)}
        </Col>
        <Col md="12" lg="8">
          <Row>
            <Col md="12">
              {/*<div className="card" data-aos="fade-up" data-aos-delay="800">*/}
              {/*  <div className="flex-wrap card-header d-flex justify-content-between">*/}
              {/*    <div className="header-title">*/}
              {/*      <h4 className="card-title">456</h4>*/}
              {/*      <p className="mb-0">Documents by status</p>*/}
              {/*    </div>*/}
              {/*    <div className="d-flex align-items-center align-self-center">*/}
              {/*      <div className="d-flex align-items-center text-primary">*/}
              {/*        <svg*/}
              {/*          xmlns="http://www.w3.org/2000/svg"*/}
              {/*          width="12"*/}
              {/*          viewBox="0 0 24 24"*/}
              {/*          fill="currentColor"*/}
              {/*        >*/}
              {/*          <g>*/}
              {/*            <circle*/}
              {/*              cx="12"*/}
              {/*              cy="12"*/}
              {/*              r="8"*/}
              {/*              fill="currentColor"*/}
              {/*            ></circle>*/}
              {/*          </g>*/}
              {/*        </svg>*/}
              {/*        <div className="ms-2">*/}
              {/*          <span className="text-gray">Under review</span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="d-flex align-items-center text-danger ms-2">*/}
              {/*        <svg*/}
              {/*          xmlns="http://www.w3.org/2000/svg"*/}
              {/*          width="12"*/}
              {/*          viewBox="0 0 24 24"*/}
              {/*          fill="currentColor"*/}
              {/*        >*/}
              {/*          <g>*/}
              {/*            <circle*/}
              {/*              cx="12"*/}
              {/*              cy="12"*/}
              {/*              r="8"*/}
              {/*              fill="currentColor"*/}
              {/*            ></circle>*/}
              {/*          </g>*/}
              {/*        </svg>*/}
              {/*        <div className="ms-2">*/}
              {/*          <span className="text-gray">Requested for Action</span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="d-flex align-items-center ms-3 text-info">*/}
              {/*        <svg*/}
              {/*          xmlns="http://www.w3.org/2000/svg"*/}
              {/*          width="12"*/}
              {/*          viewBox="0 0 24 24"*/}
              {/*          fill="currentColor"*/}
              {/*        >*/}
              {/*          <g>*/}
              {/*            <circle*/}
              {/*              cx="12"*/}
              {/*              cy="12"*/}
              {/*              r="8"*/}
              {/*              fill="currentColor"*/}
              {/*            ></circle>*/}
              {/*          </g>*/}
              {/*        </svg>*/}
              {/*        <div className="ms-2">*/}
              {/*          <span className="text-gray">Approved</span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <Dropdown>*/}
              {/*      <Dropdown.Toggle*/}
              {/*        as={Button}*/}
              {/*        variant="text-gray"*/}
              {/*        type="button"*/}
              {/*        id="dropdownMenuButtonSM"*/}
              {/*      >*/}
              {/*        This Week*/}
              {/*      </Dropdown.Toggle>*/}
              {/*      <Dropdown.Menu>*/}
              {/*        <Dropdown.Item href="#">This Week</Dropdown.Item>*/}
              {/*        <Dropdown.Item href="#">This Month</Dropdown.Item>*/}
              {/*        <Dropdown.Item href="#">This Year</Dropdown.Item>*/}
              {/*      </Dropdown.Menu>*/}
              {/*    </Dropdown>*/}
              {/*  </div>*/}
              {/*  <div className="card-body">*/}
              {/*    <Chart*/}
              {/*      options={chart1.options}*/}
              {/*      series={chart1.series}*/}
              {/*      type="area"*/}
              {/*      height="245"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </Col>
            <Col md="12" lg="12">
              <div
                className="overflow-hidden card"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="flex-wrap card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="mb-2 card-title">Recent uploaded documents</h4>
                    <p className="mb-0">
                      <svg
                        className="me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#3a57e8"
                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                        />
                      </svg>
                      recent top 10 documents
                    </p>
                  </div>
                </div>
                <div className="p-0 card-body">
                  <div className="mt-4 table-responsive">
                    <table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Category</th>
                          <th>Creator</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                      {recent.map(v=>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                {v.name}
                              </div>
                            </td>
                            <td>
                              {v.category?.name_en}
                            </td>
                            <td>{v.created_by}</td>
                            <td>
                              {moment(v.created_at).format("YYYY-MMM-DD HH:mm:ss")}
                            </td>
                          </tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md="12" lg="4">
          <Row>
            <Col md="12" lg="12">
              <div className="card" data-aos="fade-up" data-aos-delay="500">
                <div className="text-center card-body d-flex justify-content-around">
                  <div>
                    <h2 className="mb-2">
                      {visitors?.count?.toLocaleString()}
                    </h2>
                    <p className="mb-0 text-gray">Website Visitors</p>
                  </div>
                  {/*<hr className="hr-vertial" />*/}
                  {/*<div>*/}
                  {/*  <h2 className="mb-2">7,500</h2>*/}
                  {/*  <p className="mb-0 text-gray">New Visitors</p>*/}
                  {/*</div>*/}
                </div>
                <hr className={'hr-horizontal mb-5'}/>
                {visitors?.countries?.map(v=><div className={'px-3 mb-3 d-flex'}>
                  <span className={'flex-grow-1'}>{v.name ?? "NONE"}</span>
                  <div><b>{v.count?.toLocaleString()}</b></div>
                </div>)}
              </div>

              {/*<div className="card" data-aos="fade-up" data-aos-delay="1000">*/}
              {/*  <div className="flex-wrap card-header d-flex justify-content-between">*/}
              {/*    <div className="header-title">*/}
              {/*      <h4 className="card-title">New Visitors per period</h4>*/}
              {/*    </div>*/}
              {/*    <Dropdown>*/}
              {/*      <Dropdown.Toggle*/}
              {/*          as={Button}*/}
              {/*          variant="text-gray"*/}
              {/*          type="button"*/}
              {/*          id="dropdownMenuButtonSM"*/}
              {/*      >*/}
              {/*        This Week*/}
              {/*      </Dropdown.Toggle>*/}
              {/*      <Dropdown.Menu>*/}
              {/*        <Dropdown.Item href="#">This Week</Dropdown.Item>*/}
              {/*        <Dropdown.Item href="#">This Month</Dropdown.Item>*/}
              {/*        <Dropdown.Item href="#">This Year</Dropdown.Item>*/}
              {/*      </Dropdown.Menu>*/}
              {/*    </Dropdown>*/}
              {/*  </div>*/}
              {/*  <div className="card-body">*/}
              {/*    <Chart*/}
              {/*        className="d-activity"*/}
              {/*        options={chart3.options}*/}
              {/*        series={chart3.series}*/}
              {/*        type="bar"*/}
              {/*        height="230"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(Index)



// const chart1 = {
//   options: {
//     chart: {
//       fontFamily:
//         '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
//       toolbar: {
//         show: false,
//       },
//       sparkline: {
//         enabled: false,
//       },
//     },
//     colors: colors,
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: "smooth",
//       width: 3,
//     },
//     yaxis: {
//       show: true,
//       labels: {
//         show: true,
//         minWidth: 19,
//         maxWidth: 19,
//         style: {
//           colors: "#8A92A6",
//         },
//         offsetX: -5,
//       },
//     },
//     legend: {
//       show: false,
//     },
//     xaxis: {
//       labels: {
//         minHeight: 22,
//         maxHeight: 22,
//         show: true,
//         style: {
//           colors: "#8A92A6",
//         },
//       },
//       lines: {
//         show: false, //or just here to disable only x axis grids
//       },
//       categories: ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug"],
//     },
//     grid: {
//       show: false,
//     },
//     fill: {
//       type: "gradient",
//       gradient: {
//         shade: "dark",
//         type: "vertical",
//         shadeIntensity: 0,
//         gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
//         inverseColors: true,
//         opacityFrom: 0.4,
//         opacityTo: 0.1,
//         stops: [0, 50, 80],
//         colors: colors,
//       },
//     },
//     tooltip: {
//       enabled: true,
//     },
//   },
//   series: [
//     {
//       name: "total",
//       data: [94, 80, 94, 80, 94, 80, 94],
//     },
//     {
//       name: "pipline",
//       data: [72, 60, 84, 60, 74, 60, 78],
//     },
//   ],
// };

//chart2
// const chart2 = {
//   options: {
//     colors: colors,
//     plotOptions: {
//       radialBar: {
//         hollow: {
//           margin: 10,
//           size: "50%",
//         },
//         track: {
//           margin: 10,
//           strokeWidth: "50%",
//         },
//         dataLabels: {
//           show: false,
//         },
//       },
//     },
//     labels: ["Apples", "Oranges"],
//   },
//   series: [55, 75],
// };
// const chart3 = {
//   options: {
//     chart: {
//       stacked: true,
//       toolbar: {
//         show: false,
//       },
//     },
//     colors: colors,
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "28%",
//         endingShape: "rounded",
//         borderRadius: 5,
//       },
//     },
//     legend: {
//       show: false,
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["transparent"],
//     },
//     xaxis: {
//       categories: ["S", "M", "T", "W", "T", "F", "S", "M", "T", "W"],
//       labels: {
//         minHeight: 20,
//         maxHeight: 20,
//         style: {
//           colors: "#8A92A6",
//         },
//       },
//     },
//     yaxis: {
//       title: {
//         text: "",
//       },
//       labels: {
//         minWidth: 19,
//         maxWidth: 19,
//         style: {
//           colors: "#8A92A6",
//         },
//       },
//     },
//     fill: {
//       opacity: 1,
//     },
//     tooltip: {
//       y: {
//         formatter: function (val) {
//           return "$ " + val + " thousands";
//         },
//       },
//     },
//   },
//   series: [
//     {
//       name: "Successful deals",
//       data: [30, 50, 35, 60, 40, 60, 60, 30, 50, 35],
//     },
//     {
//       name: "Failed deals",
//       data: [40, 50, 55, 50, 30, 80, 30, 40, 50, 55],
//     },
//   ],
// };
