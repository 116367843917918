import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {connect} from 'react-redux';
import {inArray, wordMime} from "../../../store/helpers/common_functions";
import AddForm from "../../../helpers/AddForm";
import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {languages_const} from "./ocr_scan";

const OcrScanUploadDoc = ({onSuccess,newId}) => {

  const [languageStr, setLanguageStr] = useState();
  const [languages,setLanguages] = React.useState(languages_const);

  return <Row className={'justify-content-center'}>
    <Col md={12}>
      <div>
        <AddForm elevation={false} inline={true} method={'POST'} url={`/office/upload?document=${newId}&languages=${languageStr}`}
                 mainFields={[
                   {
                     title: "",
                     fields: [
                       {
                         name: "languages",
                         label: "Select Language",
                         setValue: (val) => {
                           if (val) {
                             const lng =JSON.parse(val);
                             setLanguageStr(lng?.join(", "))
                           }
                         },
                         submitValue:(val)=>JSON.parse(val)?.join(", "),
                         values: languages,
                         type: 'multi-select',
                         required: true
                       },
                       {
                         name: "path",
                         label: "Upload Word Document",
                         type: 'file-drag',
                         required: true,
                         fileTypes: ["DOCX"],
                         fileKey: 'file1',
                       },
                       {
                         name:"dir",
                         label:"Tmp",
                         value:"tmp",
                         hidden:true
                       },
                     ]
                   }
                 ]} onSuccess={onSuccess}/>
      </div>
    </Col>
  </Row>;
}


export default connect(mapStateToProps,mapDispatchToProps)(OcrScanUploadDoc);