
import React, {useEffect} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, BorderColor} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
const Sections = (props) => {
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }


    return <>
        <AddForm setOpen={handleClose} method={id ? 'PUT' : 'POST'} maxWidth={'md'} col={'4'} title={id ? 'Update Section' : 'Create New Section'} url={id ? `sections/update/${id}` : 'sections/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name_en",
                        label:"Name(English)",
                        required:true,
                        value:info?.name_en,
                    },
                    {
                        name:"name_rw",
                        label:"Name (Kinyarwanda)",
                        value:info?.name_rw,
                        required:true
                    },
                    {
                        name:"name_fr",
                        label: "Name (French)",
                        value:info?.name_fr,
                        required:true
                    },
                    {
                        name:"description_en",
                        type:'textarea',
                        label:"Description(English)",
                        value:info?.description_en,
                    },
                    {
                        name:"description_rw",
                        type:'textarea',
                        label:"Description (Kinyarwanda)",
                        value:info?.description_rw,
                    },
                    {
                        name:"description_fr",
                        type:'textarea',
                        label: "Description (French)",
                        value:info?.description_fr,
                    },
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DatatableComponent deleteUrl={(id)=>({
            url:`sections/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        })} addRowNumber={true} onAddNew={()=>{
            setOpen(1);
        }} actions={(id,meta,info)=>[
            <IconButton onClick={() => {
                setOpen(2);
                setId(id);
                setInfo(info);
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"Name (English)",
                name:"name_en"
            },
            {
                label:"Name (Kinyarwanda)",
                name:"name_rw"
            },
            {
                label:"Name (French)",
                name:"name_fr"
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Sections'} url={`sections/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(Sections);
