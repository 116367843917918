
import React, {useEffect} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, Block, BorderColor, VerifiedUser} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import sections from "./sections.json";
import {inArray} from "../../../store/helpers/common_functions";
const Categories = (props) => {
    const {FetchDetails,loggedUser} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [sectionId,setSectionId] = React.useState();
    const [subSectionId,setSubSectionId] = React.useState();
    const [subSubSectionId,setSubSubSectionId] = React.useState();
    const [info,setInfo] = React.useState();
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }




    const children = sections.find(v=>v.id === sectionId)?.children??[];
    const subChildren = children.find(v=>v.id === subSectionId)?.children??[];


    useEffect(()=>{
        setSubSubSectionId(null);
        setSubSectionId(null);
    },[sectionId]);
    useEffect(()=>{
        setSubSubSectionId(null);
    },[subSectionId]);

    return <>
        <AddForm setOpen={handleClose} method={id ? 'PUT' : 'POST'} title={id ? 'Update Category' : 'Create New Category'} url={id ? `categories/update/${id}` : 'categories/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name_en",
                        label:"Name(English)",
                        required:true,
                        value:info?.name_en,
                    },
                    {
                        name:"name_rw",
                        label:"Name (Kinyarwanda)",
                        value:info?.name_rw,
                        required:true
                    },
                    {
                        name:"name_fr",
                        label: "Name (French)",
                        value:info?.name_fr,
                        required:true
                    },
                    // {
                    //     name:"par_section",
                    //     label: "Section",
                    //     value:sectionId,
                    //     setValue:setSectionId,
                    //     type:'select',
                    //     values:sections.map(v=>({label:v.name_en,id:v.id})),
                    //     // required:true
                    // },
                    // {
                    //     name:"sub_section",
                    //     label: "Sub Section",
                    //     value:subSectionId,
                    //     setValue:setSubSectionId,
                    //     type:'select',
                    //     hidden:children.length<=0,
                    //     values:children.map(v=>({label:v.name_en,id:v.id})),
                    //     // required:true
                    // },
                    // {
                    //     name:"sub_sub_section",
                    //     label: "Sub Sub Section",
                    //     value:subSubSectionId,
                    //     setValue:setSubSubSectionId,
                    //     type:'select',
                    //     hidden:subChildren.length<=0,
                    //     values:subChildren.map(v=>({label:v.name_en,id:v.id})),
                    //     // required:true
                    // },
                    {
                        name:"section",
                        label:"Section",
                        value:subSubSectionId ?? subSectionId ?? sectionId,
                        hidden:true
                    },
                    {
                        name:"code",
                        label:"Code",
                        value:info?.code,
                        required:true
                    }
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DatatableComponent forceDeleteUrl={(id )=>({
            url:`categories/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        })} deleteUrl={inArray(loggedUser?.permissions,"categories-delete") ? (id,info)=>({
            url:`categories/action/single`,
            post:true,
            icon:()=>{
                return info?.status === 0 ? <VerifiedUser /> : <Block/>
            },
            color:info?.status === 0 ? 'success' : undefined,
            title:info?.status === 0 ? 'activate' : 'deactivate',
            body:{
                id,
                action:info?.status === 0 ? "active" : "block"
            }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"categories-create") ? ()=>{
            setOpen(1);
        } : undefined} actions={(id, _meta,info)=>[
            inArray(loggedUser?.permissions,"categories-update") && <IconButton onClick={() => {
                setOpen(2);
                setId(id);
                setInfo(info);
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"Name (English)",
                name:"name_en"
            },
            {
                label:"Name (Kinyarwanda)",
                name:"name_rw"
            },
            {
                label:"Name (French)",
                name:"name_fr"
            },
            {
                label:"Code",
                name:"code"
            },
            {
                label:"Status",
                name:"status",
                options: {
                    customBodyRender:(v)=><Chip size={'small'} color={v=== 0 ? 'error' : 'success'} label={v === 0 ? 'Disabled' : 'Active'}/>
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Categories'} url={`categories/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(Categories);
