import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useEffect, useState} from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectSearch({values,value,onChange,placeholder}) {
    const [newValue, setNewValue] = useState([]);

    useEffect(() => {
        if(typeof value === 'string'){
            const nVal = JSON.parse(value);
            if(Array.isArray(nVal)) {
                setNewValue(nVal);
            }
        }else if(Array.isArray(value)){
            setNewValue(value);
        }
    }, [value]);


    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={values ?? []}
            disableCloseOnSelect
            size={'small'}
            value={newValue?.map(v=>values?.find(vx=>vx.value === v)) ?? []}
            onChange={(event,newValue)=>{
                if(typeof onChange === 'function'){
                    onChange(newValue?.map(v=>v?.value));
                }
            }}
            getOptionLabel={(option) => option?.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} label="" placeholder={placeholder ?? 'Search Here'} />
            )}
        />
    );
}
