import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {connect} from 'react-redux';
import {Col, Row} from "react-bootstrap";
import AddForm from "../../../helpers/AddForm";
import {downloadBase64} from "../../../store/helpers/common_functions";
import React, {useEffect, useState} from "react";
import {languages_const} from "./ocr_scan";
import {Button, CircularProgress, Typography} from "@mui/material";
import {ArrowForwardSharp, FileCopy, Splitscreen} from "@mui/icons-material";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import PageLoading from "../../../components/PageLoading";
import DeleteDialog from "../../../helpers/DeleteDialog";

const OcrCropSplitPDF = (props) => {

  const {onSuccess,newId,fileId,FetchDetails} = props;

  const [sWidth, setSWidth] = useState(null);
  const [sHeight, setSHeight] = useState(null);
  const [tWidth, setTWidth] = useState(null);
  const [tHeight, setTHeight] = useState(null);
  const [file, setFile] = useState(null);
  const [baseFile, setBaseFile] = useState(null);
  const [loading,setLoading] = useState(false);
  const [base64,setBase64] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(null);
  const [id,setId] = useState();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const loadFile = async (fileId)=>{
    setPageLoading(true);
    const resp = await FetchDetails(`documents-files/${fileId}`);
    setPageLoading(false);
    if(resp.status){
      setFile(resp.data.data.file);
      setLanguages(resp.data.data.languages.split(",")?.map(v=>({label:languages_const.find(vi=>vi.value === v?.trim())?.label,id:v?.trim()})));
      setBaseFile(resp.data.data.file_original);
    }
  }

  const downloadBase = async ()=>{
    setLoading(true);
    setBase64(null);
    const base = await downloadBase64("files/download",{
      path:file.path
    },"application/pdf");
    setLoading(false);
    if(base) {
      setBase64(base);
    }
  }




  useEffect(() => {
    if(file !== null){
      downloadBase().then();
    }
  }, [file]);

  useEffect(()=>{
    if(fileId !== id){
      setId(fileId);
    }
  },[fileId]);

  useEffect(() => {
    if(id){
      loadFile(id).then();
    }
  }, [id]);



  useEffect(() => {
    if(language === "rw"){
      setSWidth(-610);
      setSHeight(-55);
      setTWidth(-90);
      setTHeight(-0);
    }else if(language === "en"){
      setSWidth(-624);
      setSHeight(-55);
      setTWidth(-316);
      setTHeight(-0);
    }else if(language === "fr"){
      setSWidth(-624);
      setSHeight(-55);
      setTWidth(-530);
      setTHeight(-0);
    }
  }, [language]);



  return pageLoading ? <PageLoading/> : <div>
    <Row>
      <Col md={5}>
        <DeleteDialog info={true} onSuccess={onSuccess} title={'Split Document'} buttonTitle={"Split"} bodyText={'Are you sure you want split this document ?'} url={'documents-files/create'} open={openSubmit} setOpen={()=>setOpenSubmit(0)} usePost={true} body={
          {
            file,
            document:newId,
            split:true,
            language,
          }
        } {...props}/>

        {file !== null && <>
          <AddForm cancelLabel={'Reset Splitting'} inline={true} setOpen={(_,data) => {
            if(!data){
              setFile(baseFile);
            }
            return false;
          }} method={'POST'} title={'Adjust Split Document'}
                   url={`pdf/crop-new?filePath=${baseFile?.path}&fileName=${baseFile?.name}`}
                   mainFields={[
                     {
                       title: "",
                       fields: [
                         {
                           label:"Select language",
                           name:"language",
                           type:'select',
                           values:languages,
                           value:language,
                           setValue:setLanguage,
                           required:true
                         },
                         {
                           name: "sWidth",
                           value: sWidth,
                           setValue: setSWidth,
                           urlParam: true,
                           col: 6,
                           label: "Right"
                         },
                         {
                           name: "sHeight",
                           value: sHeight,
                           urlParam: true,
                           setValue: setSHeight,
                           col: 6,
                           label: "Top",
                         },
                         {
                           name: "tWidth",
                           value: tWidth,
                           urlParam: true,
                           col: 6,
                           label: "Left",
                           setValue: setTWidth,
                         },
                         {
                           name: "tHeight",
                           label: "Bottom",
                           urlParam: true,
                           col: 6,
                           value: tHeight,
                           setValue: setTHeight,
                         },
                       ]
                     }
                   ]} onSuccess={(res) => {
            setFile(res.data[0]);
          }} buttonTitle={'Split'}/>
          <div className={'text-center'}>
            <div>
              <Button variant={'contained'} onClick={()=>setOpenSubmit(1)} disabled={scanning} startIcon={scanning ? <CircularProgress size={25}/> : <ArrowForwardSharp/>}>Confirm And Continue</Button>
            </div>
          </div>
        </>
        }
      </Col>
      <Col md={7}>
        {base64 !== null ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl={base64}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                  ]}/>
        </Worker>  : <div className={'d-flex justify-content-center align-items-center'} style={{minHeight: "75vh"}}>
          <div className={'text-center'} style={{color: "#bdbdbd"}}>
            {loading ? <CircularProgress/> : <>
              <FileCopy style={{fontSize: "100px"}}/>
              <div className={'mt-2'}>
                <Typography variant={'h3'} color={'inherit'}>PDF Preview</Typography>
              </div>
            </>}
          </div>
        </div>}
      </Col>
    </Row>
  </div>;
}


export default OcrCropSplitPDF;