import React, {useEffect, useState} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, BorderColor} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";

const Logs = ({FetchDetails}) => {

        const [institutions, setInstitutions] = useState([]);
        const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [models, setModels] = useState([]);

    const loadInstitutions = async () => {
            const resp = await FetchDetails("dropdowns/institutions?language=en");
            if (resp.status) {
                setInstitutions(resp.data.data);
            }
        }

        const loadUsers = async () => {
            const resp = await FetchDetails("users/table?limit=1000");
            if (resp.status) {
                setUsers(resp.data.data.data);
            }
        }

        const loadRoles = async () => {
            const resp = await FetchDetails("dropdowns/roles");
            if (resp.status) {
                setRoles(resp.data.data);
            }
        }

        const loadModels = async () => {
            const resp = await FetchDetails("dropdowns/models");
            if (resp.status) {
                setModels(resp.data.data);
            }
        }

        useEffect(()=>{
            loadInstitutions().then();
            loadUsers().then();
            loadRoles().then();
            loadModels().then();
        },[FetchDetails]);

        return <>
            <DatatableComponent
                //     deleteUrl={(id)=>({
                //     url:`logs/action/single`,
                //     post:true,
                //     body:{
                //         id,
                //         action:"block"
                //     }
                // })}
                filters={[
                    {
                        label: "Select User",
                        type: "select",
                        values: users.map(v=>({label:v.name,value:`${v.id}`})),
                        name: "userId"
                    },
                    {
                        label: "Select Institution",
                        type: "select",
                        values: institutions.map(v=>({...v,value:`${v.value}`})),
                        name: "institutionId"
                    },
                    {
                        label: "Select Role",
                        type: "select",
                        values: roles.map(v=>({...v,value:`${v.value}`})),
                        name: "roleId"
                    },
                    {
                        label: "Model",
                        type: "select",
                        name: "model",
                        values: models.map(v=>({...v,value:`${v.value}`})),
                    }
                ]}
                addRowNumber={true} elevation={1} columns={[
                {
                    label: "Model",
                    name: "model"
                },
                {
                    label: "Message",
                    name: "message"
                },
                {
                    label: "Update By",
                    name: "updatedBy"
                },
                {
                    label: "Created At",
                    name: "updatedAt",
                    options: {
                        customBodyRender: (v) => moment(v).format("YYYY-MMM-DD HH:mm:ss")
                    }
                }
            ]} title={'System Logs'} url={`logs/table`}/>
        </>;
    }
    export default connect(mapStateToProps, mapDispatchToProps)(Logs);
