import {dispatchHandler} from "../helpers/dispatchHandler";
import axios from "axios";
import {
    ERROR, GET_RESOURCES, GET_TECHNOLOGIES, SUCCESS,
    USER_INFO,
} from "./type";
import {errorToast} from "../../helpers/Notification";
import {saveAs} from "file-saver";
import {getError} from "../helpers/common_functions";

export const header = {
    headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
};

export const systemLogin =
    (request) =>
        async (dispatch) => {
            try {
                const URL = `auth/login`;
                const {data} = await axios.post(URL, request);
                if (data) {
                    localStorage.setItem("token", data.data.token);
                    localStorage.setItem("user", JSON.stringify(data.data));

                    dispatchHandler({
                        type: USER_INFO,
                        data: data.data,
                        dispatch,
                    });
                    window.location.href = "/dashboard";
                }
                return data;
            } catch (error) {
                dispatchHandler({
                    type: ERROR,
                    data: getError(error),
                    dispatch,
                });
                return null;
            }
        };

export const sendResetEmail =
    (request) =>
        async (dispatch) => {
            try {
                const URL = `/v1/auth/forgot-password`;
                const {data} = await axios.post(URL, request);
                if (data) {
                    dispatchHandler({
                        type: SUCCESS,
                        data: data?.message ?? "Success",
                        dispatch,
                    });
                }
                return data;
            } catch (error) {
                dispatchHandler({
                    type: ERROR,
                    data: error?.data?.message ?? "Unknown error",
                    dispatch,
                });
                return null;
            }
        };

export const resetPassword =
    (request) =>
        async (dispatch) => {
            try {
                const URL = `/v1/auth/reset-password`;
                const {data} = await axios.post(URL, request);
                if (data) {


                    dispatchHandler({
                        type: SUCCESS,
                        data: data?.message ?? "Success",
                        dispatch,
                    });
                }
                return data;
            } catch (error) {
                dispatchHandler({
                    type: ERROR,
                    data: error?.data?.message ?? "Unknown error",
                    dispatch,
                });
                return null;
            }
        };
export const submitGeneralForm =
    (URL, body, onUploadProgress, method = null,responseType) =>
        async () => {
            try {
                const {data} = await (method === 'PUT' ? axios.put(URL, body, {
                    ...header,
                    onUploadProgress,
                    responseType
                }) : axios.post(URL, body, {...header, onUploadProgress,
                    responseType}));
                return {data, status: true};
            } catch (error) {
                return {data: error, status: false};
            }
        };


export const getLoggedUser =
    () =>
        async (dispatch) => {
            try {

                const data = JSON.parse(localStorage.getItem("user"));
                dispatchHandler({
                    type: USER_INFO,
                    data: {...data,permissions:JSON.parse(data.permissions)},
                    dispatch,
                });

            } catch (error) {

            }
        };

export const downloadPostBase64 =
    (path,fileType) =>
        async () => {
            try {
                const axios_URL = `/v1/files/download`;
                const formData = new FormData();
                formData.append("path", path);
                const {data} = await axios.post(
                    axios_URL,
                    formData,
                    {
                        ...header,
                        responseType: "blob",
                    }
                );
                if (data) {
                    const file = new Blob([data], {
                        type: fileType,
                    });
                    return await blobToBase64(file);
                }
            } catch (error) {
                return null;
            }
        };


export const generalDownload =
    (id, type, name, specialName) =>
        async () => {
            try {
                const axios_URL = `/api/download/file/${id}?type=${type}&key=${name}&specialName=${specialName}`;
                const {data, headers} = await axios.get(
                    axios_URL,
                    {
                        ...header,
                        responseType: "blob",
                    }
                );
                if (data) {
                    const file = new Blob([data], {
                        type,
                    });
                    let filename = headers['content-disposition'].split('filename=').pop();
                    saveAs(file, filename ?? name);
                }
            } catch (error) {
                console.log(error);
                errorToast(error?.response?.data?.message ?? "Server Error");
            }
        };

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const deleteAction =
    (URL) =>
        async () => {
            try {
                const {data} = await axios.delete(URL, header);
                return {data, status: true};
            } catch (error) {
                return {data: error, status: false};
            }
        };

export const fetchDetails =
    (URL) =>
        async () => {
            try {
                const {data} = await axios.get(URL, header);
                return {data, status: true};
            } catch (error) {
                return {data: error, status: false};
            }
        };
export const putAction =
    (URL, map) =>
        async () => {
            try {
                const {data} = await axios.put(URL, map, header);
                return {data, status: true};
            } catch (error) {
                return {data: error, status: false};
            }
        };

export const getTechnologies =
    () =>
        async (dispatch) => {
            try {
                const URL = 'api/technology/get';
                const {data} = await axios.get(URL, header);
                dispatchHandler({
                    type: GET_TECHNOLOGIES,
                    data: data.list,
                    dispatch,
                });
                return {data, status: true};
            } catch (error) {
                return {data: error, status: false};
            }
        };


