import {buildOfficeUrl, downloadBase64, downloadBase64Get, wordMime} from "../../../store/helpers/common_functions";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, CardContent, CardHeader, Chip, CircularProgress} from "@mui/material";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {connect} from 'react-redux';
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {OpenInBrowser} from "@mui/icons-material";
import {AiFillFileWord} from "react-icons/ai";

const ViewDocument = ({FetchDetails,loggedUser}) => {
    const [keywords,setKeywords] = React.useState([]);
    const [base64,setBase64] = React.useState();
    const [path,setPath] = React.useState();
    const [info,setInfo] = React.useState();
    const [loading,setLoading] = React.useState(false);
    const navigate = useNavigate();
    const {id} = useParams();
    const downloadBase = async ()=>{
        setLoading(true);
        const base = await downloadBase64Get(path,"application/pdf");
        setLoading(false);
        if(base) {
            setBase64(base);
        }else{
            setPath(null);
        }
    }

    const loadInfo = async ()=>{
        setLoading(true);
        const resp = await FetchDetails(`documents-files/${id}`);
        setLoading(false);
        if(resp.status){
            const data = resp.data?.data;

            const doc = data?.file?.mime === wordMime;

            if(!doc) {
                const file = data.file;
                setPath(data.file_url);
            }
                setKeywords(data?.keywords?.split(","));
                setInfo({...data,doc});

        }
    }



    useEffect(()=>{
        if(path){
            downloadBase().then();
        }
    },[path]);

    useEffect(()=>{
        if(id){
            loadInfo().then();
        }
    },[id]);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    return loading ? <div className={'text-center p-5 m-5'} style={{minHeight: "200px"}}>
        <CircularProgress/>
    </div> : <Card>
        <CardHeader title={'Keywords & Document'} action={<Button startIcon={<OpenInBrowser/>} onClick={() => window.open(`/full/${encodeURIComponent(path)}`)}>Full Preview</Button>}/>
        <CardContent>
            <div className={'mb-3'}>
                {keywords.map(v=><Chip label={v} size={'small'} className={'me-2'}/>)}
            </div>
            {info?.doc ? <div>
                <div className={'text-center p-5'}>
                    <Button onClick={()=>{
                        window.open(info?.office_url ?? buildOfficeUrl(loggedUser?.office_key,info?.id))
                    }} startIcon={<AiFillFileWord/>}>Open Word document editor in new page</Button>
                </div>
            </div> : base64 ?
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={base64}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance,
                            ]}/>
                </Worker> : <div>

                </div>}
        </CardContent>
    </Card>;

}

export default connect(mapStateToProps,mapDispatchToProps)(ViewDocument);