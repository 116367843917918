
import React, {useEffect} from 'react'
import DatatableComponent from "../../../../helpers/DatatableComponent";
import moment from "moment";
import {Accessibility, BorderColor, Construction, RemoveRedEye} from "@mui/icons-material";
import {Button, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {useNavigate} from "react-router-dom";
import Status from "../../../../components/partials/status";
import SubmitApplication from "../../../../components/partials/submit_application";
import {defaultFilters, getError, getSuccessMessage, inArray} from "../../../../store/helpers/common_functions";
import BasicMenu from "../../../../components/basic_menu";
import Swal from "sweetalert2";
import {submitGeneralForm} from "../../../../store/User/action";
import {errorToast, successToast} from "../../../../helpers/Notification";
const InForceDocuments = (props) => {
    const {loggedUser,SubmitGeneralForm} = props;
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const navigate = useNavigate();
    // const handleClose = ()=>{
    //     setOpen(0);
    //     setId(null);
    //     setInfo(null);
    // }


    const reload = ()=>setChange(v=>v+1);


    return <>
        <DatatableComponent filters={defaultFilters} deleteUrl={inArray(loggedUser?.permissions,"documents-delete") ? (id)=>({
            url:`documents/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"documents-create") ? ()=>{
            navigate('/dashboard/in-force/create');
        } : undefined} actions={(id, _meta,data)=>[
            // data?.status !== 1 && inArray(loggedUser?.permissions,"documents-update") &&  <Button variant={'outlined'} size={'small'} className={'me-1'} onClick={() => {
            //     navigate(`/dashboard/in-force/update/${id}`);
            // }} title={'Amend'}>
            //     Amend
            // </Button> ,
            // data?.status !== 1 && <Button variant={'outlined'} size={'small'} onClick={()=>{
            //     navigate(`/dashboard/in-force/update/${id}?amend=1`);
            // }} title={'Repeal'}>
            //     Repeal
            // </Button>,
            data?.status !== 1 && <BasicMenu onClick={(val)=>{
                if(val === 0){
                    navigate(`/dashboard/in-force/update/${id}?amend=1`);
                }else if(val === 1){
                    navigate(`/dashboard/in-force/update/${id}?amend=2`);
                }else if(val === 2){
                    Swal.fire({
                        title:"Repeal this law",
                        text:"Are you sure you want to repeal this law ?",
                        showConfirmButton:true,
                        showCancelButton:true,
                        showLoaderOnConfirm:true,
                        preConfirm: async (inputValue)=> {
                            const resp = await SubmitGeneralForm(`documents/repel-skip/${id}`,{},undefined,"PUT");
                            if(resp.status){
                                successToast(getSuccessMessage(resp));
                                return resp.data;
                            }else{
                                errorToast(getError(resp));
                            }
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then(r=>{
                       if(r.isConfirmed){
                           reload();
                       }
                    });
                }
            }}/>,
            data?.status_publish === 3 && inArray(loggedUser?.permissions,"documents-update") && <IconButton onClick={() => {
                navigate(`/dashboard/in-force/update/${id}`);
            }}>
                <BorderColor />
            </IconButton>,
            <IconButton onClick={() => {
                navigate(`/dashboard/in-force/details/${id}`);
            }}>
                <RemoveRedEye />
            </IconButton>,
            inArray([0,1],data?.status) && <SubmitApplication {...props} info={data} size={'small'} onSuccess={reload}/>
        ]} elevation={1} columns={[
            {
                label:"Law Name",
                name:"name"
            },
            {
                label:"Law Number",
                name:"case_no"
            },
            {
                label:"Category",
                name:"category.name_en"
            },
            {
                label:"Sub Category",
                name:"sub_category.name_en"
            },
            {
                label:"Enforcement Date",
                name:"date",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            },
            {
                label:"Status",
                name:"status",
                options:{
                    customBodyRender:(v,_meta,info)=><Status status={v} info={info}/>
                }
            }
        ]} title={'Law in-force Documents'} url={`documents/table?change=${change}&section=1.1`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(InForceDocuments);
