import React from 'react'
import logo from "../../../assets/images/auth/logo.png";
const Logo = (props ) => {

    return (
        <>
            <img src={logo} alt="" style={{height:"30px"}}/>
         </>
        )
    }

    export default Logo
