import React, {useState} from 'react'
import {Row, Col, Image, Form, Button, Alert} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'



// img
import auth2 from '../../../assets/images/auth/02.png'
import ReCAPTCHA from "react-google-recaptcha";
import {CircularProgress} from "@mui/material";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {connect} from 'react-redux';
import {getError, getSuccessMessage} from "../../../store/helpers/common_functions";

const Recoverpw = ({SubmitGeneralForm}) => {
   let navigate = useNavigate()
   const [captcha, setCaptcha] = useState(undefined);
   const [loading,setLoading] = useState(false);
   const [message,setMessage] = useState(undefined);
   return (
       <Card className="card-bg shadow-none mb-0">
          <Card.Body>
             {message ? (
                 <Alert variant={`${!message.error ? 'success' : 'danger'} d-flex align-items-center`} role="alert">
                    <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                       <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                    </svg>
                    <div>
                       {message.message}
                    </div>
                 </Alert>
             ) : null}
             <h2 className="mb-2">Reset Password</h2>
             <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
             <Form action={""} method={"POST"} onSubmit={async (event) => {
                event.preventDefault();
                if(loading) return;

                setLoading(true);
                const data = new FormData(event.currentTarget);
                const resp = await SubmitGeneralForm('auth/forgot-password',{
                   email:data.get("email"),
                   captcha
                });

                if(resp.status){
                   setMessage({message:getSuccessMessage(resp)})
                   navigate('/auth/confirm-mail');
                }else {
                   setMessage({message:getError(resp),error:true});
                }


                setLoading(false);
             }}>
                <Row>
                   <Col lg="12" className="col-lg-12">
                      <Form.Group className="floating-label mb-3 position-relative">
                         <div className={'position-absolute top-0 py-2 px-3'} style={{left:0}}>
                            <i className={'fa fa-envelope'}></i>
                         </div>
                         <Form.Control type="email" name={'email'} className="form-control ps-5 rounded-0" id="email" aria-describedby="email" placeholder="Email" />
                      </Form.Group>
                   </Col>
                   <Col lg={12}>
                      <div className={'mb-3'}>
                         <ReCAPTCHA
                             sitekey="6LeLwB0lAAAAAHjH_Ve0hjkissvnjPADgLa5JSkP"
                             onChange={(v)=>{
                                setCaptcha(v);
                             }}
                         />
                      </div>
                   </Col>
                </Row>
                <Button type="submit" variant="btn btn-primary w-100" disabled={loading}>{loading && <CircularProgress size={20} color={'error'}/>} Reset Password</Button>
             </Form>
          </Card.Body>
       </Card>
   )
}

export default connect(mapStateToProps,mapDispatchToProps)(Recoverpw)
