import OCRScan from "../ocr_scan";
import React, {useEffect} from "react";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {Col, FormGroup, Row} from "react-bootstrap";
import Status from "../../../../components/partials/status";
import PublishStatus from "../../../../components/partials/publish_status";
import {getQuery} from "../../../../store/helpers/common_functions";

const InForceCreate = ({FetchDetails}) => {
    const [info,setInfo] = React.useState();
    const [categories,setCategories] = React.useState([]);
    const [subCategories,setSubCategories] = React.useState([]);
    const [categoryId,setCategoryId] = React.useState();
    const [loadingPage,setLoadingPage] = React.useState(false);
    const {id} = useParams();
    const [cachedId, setCachedId] = React.useState(id);


    const section = "1.1";
    const loadSubCategories = async ()=>{
        const resp = await FetchDetails(`dropdowns/sub-categories?category=${categoryId}`);
        if(resp.status){
            setSubCategories(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }





    const loadCategories = async ()=>{
        const resp = await FetchDetails(`dropdowns/categories?section=${section}`);
        if(resp.status){
            setCategories(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }

    const loadInfo = async (id)=>{
        setLoadingPage(true);
        const resp = await FetchDetails(`documents/${id}`);
        setLoadingPage(false);
        if(resp.status){
            const data = resp.data?.data;
            setCategoryId(data?.category?.id);
            setInfo(data);
        }
    }

    useEffect(()=>{
        loadCategories().then();
    },[]);


    useEffect(()=>{
        if(categoryId){
            loadSubCategories().then();
        }
    },[categoryId]);

    useEffect(()=>{
        if(id && !info){
            loadInfo(id).then();
        }
    },[id,info]);

    const preSetInfo = (info2)=>{
        if(info2?.id){
            setCachedId(info2.id?.toString());
            loadInfo(info2.id).then();
        }
    }

  return <OCRScan section={section} amend={getQuery('amend')} info={info} setInfo={preSetInfo} id={id} loadingPage={loadingPage} fields={[
      {
          name: "name",
          label: "Name",
          required: true,
          value:info?.name,
      },
      {
          name: "case_no",
          label: "Law no (EN)",
          value:info?.case_no,
      },
      {
          name: "case_no_rw",
          label: "Law no (KN)",
          value:info?.case_no_rw,
      },
      {
          name: "case_no_fr",
          label: "Law no (FR)",
          value:info?.case_no_fr,
      },
      {
          name: "section",
          label: "Section",
          hidden: true,
          value:section
      },
      {
          name: "date",
          label: "Enforcement Date",
          value: info?.date,
          type: 'date',
          required: true
      },
      {
          name: "category",
          label: "Select Category",
          value: categoryId,
          values: categories,
          setValue: setCategoryId,
          type: 'select',
          required: true
      },
      {
          name: "sub_category",
          label: "Select Sub Category",
          value: info?.sub_category?.id,
          values: subCategories,
          type: 'select',
          required: true
      },
  ]} formTitle={'Laws in force'} details={
      <Row>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Case Name</span>
                  <div>{info?.name}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Law No (EN)</span>
                  <div>{info?.case_no}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Law No (KN)</span>
                  <div>{info?.case_no_rw}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Law No (FR)</span>
                  <div>{info?.case_no_fr}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Enforcement Date</span>
                  <div>{info?.date}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Category</span>
                  <div>{info?.category?.name_en}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Sub Category</span>
                  <div>{info?.sub_category?.name_en}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Status</span>
                  <div><Status status={info?.status} info={info}/></div>
              </FormGroup>
          </Col>
      </Row>
  }/>
}

export default connect(null,mapDispatchToProps)(InForceCreate);