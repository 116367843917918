import {Card, CardContent} from "@mui/material";
import moment from "moment";
import Status from "./status";

const Comments = ({comments}) => {
  return comments?.length>0 &&
      <Card variant={'outlined'} className={'mb-2'}>
          <CardContent>
              <h4>Comments</h4>
              {comments.map(v=><div className={'mb-2'}>
                  <div><a href="#">{v.updated_by}</a></div>
                  <div style={{fontSize:"12px"}}>{moment(v.updated_at).format("YYYY-MMM-DD HH:mm:ss")}</div>
                  <div style={{fontSize:"12px"}}><Status status={v.status} size={'small'}/></div>
                  <div style={{fontSize:"13px"}}>{v.comments}</div>
              </div>)}
          </CardContent>
      </Card>;
}

export default Comments;