
import React, {useEffect} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, Block, BorderColor, VerifiedUser} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {inArray} from "../../../store/helpers/common_functions";
const Judges = (props) => {
    const {loggedUser,FetchDetails} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [collections,setCollections] = React.useState([]);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }

    const loadCollections = async ()=>{
        const resp = await FetchDetails("dropdowns/collections");
        if(resp.status){
            setCollections(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }


    useEffect(() => {
        loadCollections().then();
    }, []);



    return <>
        <AddForm setOpen={handleClose} col={6} method={id ? 'PUT' : 'POST'} title={id ? 'Update Judge' : 'Create New Judge'} url={id ? `judges/update/${id}` : 'judges/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name",
                        label:"Name",
                        required:true,
                        value:info?.name,
                    },
                    {
                        name:"email",
                        label:"Email",
                        required:true,
                        type:'email',
                        value:info?.email,
                    },
                    {
                        name:"phone",
                        label:"Phone Number",
                        type:'tel',
                        value:info?.phone,
                    },
                    {
                        name:"code",
                        label:"Code",
                        value:info?.code
                    },
                    {
                        name:"position",
                        label:"Position",
                        value:info?.position
                    },
                    {
                        name:"court",
                        label:"Court",
                        value:info?.court
                    },
                    {
                        name:"department",
                        label:"Department",
                        value:info?.department
                    },
                    {
                        name:"collection",
                        label:"Collection",
                        value:info?.collection?.id,
                        type:'select',
                        values:collections
                    }
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DatatableComponent forceDeleteUrl={(id )=>({
            url:`judges/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        })} deleteUrl={inArray(loggedUser?.permissions,"judges-delete") ? (id,info)=>({
            url:`judges/action/single`,
            post:true,
            icon:()=>{
                return info?.status === 0 ? <VerifiedUser /> : <Block/>
            },
            color:info?.status === 0 ? 'success' : undefined,
            title:info?.status === 0 ? 'activate' : 'deactivate',
            body:{
                id,
                action:info?.status === 0 ? "active" : "block"
            }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"judges-create") ? ()=>{
            setOpen(1);
        } : undefined} actions={(id, _meta,info)=>[
            inArray(loggedUser?.permissions,"judges-create") && <IconButton onClick={() => {
                setOpen(2);
                setId(id);
                setInfo(info);
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"Name",
                name:"name"
            },
            {
                label:"Collection",
                name:"collection.name_en"
            },
            {
                label:"Email Address",
                name:"email"
            },
            {
                label:"Phone Number",
                name:"phone"
            },
            {
                label:"Code",
                name:"code"
            },
            {
                label:"Position",
                name:"position"
            },
            {
                label:"Court",
                name:"court"
            },
            {
                label:"Department",
                name:"department"
            },
            {
                label:"Status",
                name:"status",
                options: {
                    customBodyRender:(v)=><Chip size={'small'} color={v=== 0 ? 'error' : 'success'} label={v === 0 ? 'Disabled' : 'Active'}/>
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Judges'} url={`judges/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(Judges);
