import {Navigate, Outlet} from "react-router-dom";
import {Col, Image, Row} from "react-bootstrap";
import logo from "../assets/images/auth/logo.png";
import auth1 from "../assets/images/auth/map.png";
import React from "react";
import {ToastContainer} from "react-toastify";

const SignInLayout = () => {

    const user = localStorage.getItem("user")
  return  !user ? <div className={'login-background d-flex flex-column align-items-center justify-content-center w-100 vh-100'}>
      <div className={'flex-grow-1 w-100 d-flex align-items-center justify-content-center'}>
          <section className="w-100">
              <Row className="m-0 align-items-center justify-content-center">
                  <Col md="5" lg={'4'} xl={'4'} xxl={'4'} className={'m-0 text-center'}>
                      <ToastContainer/>
                      <div className="d-inline-flex w-100 align-items-center justify-content-center mb-3 flx-header text-start">
                          <div>
                              <img src={require('../assets/images/auth/balance.png')} style={{backgroundImage:`url('${require('../assets/images/auth/flag.png')}')`,backgroundSize:'cover',backgroundPosition:'center',width:'100%',height:'100%',borderRadius:'50%',backgroundBlendMode:'multiply',backgroundColor:"gray"}} alt="logo"/>
                          </div>
                          <div className={'flex-grow-1 ps-3 text-white text-start'} style={{fontSize:"1.5rem"}}>
                              <div className={'bold'} style={{fontWeight:"bolder"}}>Welcome to</div>
                              <div>Amategeko Admin Panel</div>
                          </div>
                      </div>
                      <Outlet/>
                  </Col>
              </Row>
          </section>
      </div>
      <div className={'w-100 text-white px-5'}>
          <div className={'py-3 text-center'} style={{borderTop:"1px solid #cfcfcf"}}>
              ©2024 Republic of Rwanda.
          </div>
      </div>
  </div> : <Navigate to={'/dashboard'}/>
}
export default SignInLayout;