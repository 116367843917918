import OCRScan from "../ocr_scan";
import React, {useEffect} from "react";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {Col, FormGroup, Row} from "react-bootstrap";
import Status from "../../../../components/partials/status";
import PublishStatus from "../../../../components/partials/publish_status";

const LawReportCreate = ({FetchDetails}) => {
    const [info,setInfo] = React.useState();
    const [collections,setCollections] = React.useState([]);
    const [volumes,setVolumes] = React.useState([]);
    const [loadingPage,setLoadingPage] = React.useState(false);

    const {id} = useParams();

    const loadCollections = async ()=>{
        const resp = await FetchDetails("dropdowns/collections");
        if(resp.status){
            setCollections(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }

    const loadVolumes = async ()=>{
        const resp = await FetchDetails("dropdowns/volumes");
        if(resp.status){
            setVolumes(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }

    const loadInfo = async (id)=>{
        setLoadingPage(true);
        const resp = await FetchDetails(`documents/${id}`);
        setLoadingPage(false);
        if(resp.status){
            const data = resp.data?.data;
            setInfo(data);
        }
    }

    useEffect(()=>{
        loadCollections().then();
        loadVolumes().then();
    },[]);


    useEffect(()=>{
        if(id && !info){
            loadInfo(id).then();
        }
    },[id,info]);

    const preSetInfo = (info2)=>{
        if(info2?.id){
            loadInfo(info2.id).then();
        }
    }

    const section = "2.2.1";

  return <OCRScan section={section} info={info} setInfo={preSetInfo} id={id} loadingPage={loadingPage} fields={[
      {
          name: "name",
          label: "Name",
          required: true,
          value:info?.name,
      },
      {
          name: "section",
          label: "Section",
          hidden: true,
          value:section
      },
      {
          name: "date",
          label: "Date",
          value: info?.date,
          type: 'date',
          required: true
      },
      {
          name: "collection",
          label: "Select Collection",
          values: collections,
          value:info?.collection?.id ?? info?.collection,
          type: 'select',
          required: true
      },
      {
          name: "volume",
          label: "Select Volume",
          values: volumes,
          value:info?.volume?.id ?? info?.volume,
          type: 'select',
          required: true
      },
  ]} formTitle={'Law Report Document'} details={
      <Row>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Name</span>
                  <div>{info?.name}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Date</span>
                  <div>{info?.date}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Collection</span>
                  <div>{info?.collection?.name}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Volume</span>
                  <div>{info?.volume?.name_en}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Status</span>
                  <div><Status status={info?.status} info={info}/></div>
              </FormGroup>
          </Col>
      </Row>
  }/>
}

export default connect(null,mapDispatchToProps)(LawReportCreate);