import {Add, Delete, FileCopy, FilterList, PictureAsPdf, Refresh} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import {connect} from "react-redux";

import MUIDataTable, {
  DisplayData,
  MUIDataTableOptions,
  SelectableRows,
} from "mui-datatables";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import DeleteDialog from "./DeleteDialog";
// import {downloadBase64} from "../services/common";
import {saveAs} from "file-saver";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {inArray} from "../store/helpers/common_functions";

// export type FilterDropdownItem = { label: string; value: string };
//
// export type FilterType = "text" | "select" | 'number' | 'date';
//
// export type FilterItem = {
//   name: string;
//   label: string;
//   value?: string | undefined;
//   type?: FilterType;
//   values?: Array<FilterDropdownItem> | undefined;
// };
//
// type IProps = {
//   columns: Array<any>;
//   url: string;
//   exportButtons?: boolean | undefined | null;
//   titleClass?: string | undefined | null;
//   componentClass?: string | undefined | null;
//   processData?: (data: any) => any;
//   title?: any;
//   rowsSelected?:any[] | undefined;
//   onRowSelectionChange?: (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) => void;
//   customToolbarSelect?: (
//       selectedRows: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } },
//       displayData: DisplayData,
//       setSelectedRows: (rows: number[]) => void,
//   ) => React.ReactNode;
//   elevation?: number;
//   useMeetingUrl?: boolean;
//   selectableRowsHideCheckboxes?: boolean;
//   onRowClick?: (
//     rowData: string[],
//     rowMeta: { dataIndex: number; rowIndex: number }
//   ) => void;
//   actions?: (id: string, meta?: any, row?: any) => Array<ReactJSXElement>;
//   deleteUrl?: (id: string, row?: any) => string | undefined;
//   pk?: string;
//   selectableRows?: SelectableRows | undefined;
//   addRowNumber?: boolean | undefined;
//   filters?: Array<FilterItem> | undefined;
//   refresh?: boolean;
//   isLocationFilter?: boolean;
//   emitFilterUrl?: (url: string) => void;
//   ExpandedRows?: any;
//   originData?:any[]
// };

const SerializeObject = (
  obj,
  prefix
) => {
  let str = [],
    p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p;
      const v = obj[p];
      if (typeof v === "object") {
        str.push(SerializeObject(v, k));
      } else if (typeof v !== "function") {
        str.push(
          encodeURIComponent(k) + "=" + encodeURIComponent(v?.toString() ?? "")
        );
      }
    }
  }

  return str.join("&");
};

const makeId = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const uniqueId = () => makeId(10);

const DatatableComponent = (props) => {
  const {
    columns,
    filters,
    actions,
    addRowNumber,
    pk,
    selectableRows,
    url,
    title,
    deleteUrl,
    forceDeleteUrl,
    processData,
    elevation,
    onRowClick,
    refresh,
    isLocationFilter,
    loggedUser,
    emitFilterUrl,
    ExpandedRows,
    originData,
    customEmptyLabel,
    onAddNew,
      addButton,
      customToolbarSelect,
    rowsSelected,
      onRowSelectionChange,
    titleClass,
    componentClass,
      exportButtons,
    FetchDetails
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const [page, setPage] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState();
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [error, setError] = React.useState();
  const [errorStatus, setErrorStatus] = React.useState();
  const [columns0, setColumns0] = React.useState([]);
  const [tableId, setTableId] = React.useState(uniqueId());

  const [tableState, setTableState] = React.useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(0);
  const [change, setChange] = React.useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = React.useState();
  const [filters0, setFilters0] = useState([]);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (filters) {
      setFilters0(filters);
    }
  }, [filters]);

  useEffect(() => {
    const primary = pk ?? "id";
    let realColumns = columns?.filter(v=>v) ?? [];
    if (addRowNumber) {
      realColumns = [
        {
          name: "#",
          label: "",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => (
              <Chip label={Number(tableMeta.rowIndex) + 1} />
            ),
          },
        },
        ...realColumns,
      ];
    }

    if (deleteUrl || actions || forceDeleteUrl) {
      realColumns = [
        ...realColumns,
        {
          name: primary,
          label: "Action",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (id, meta, row) => {
              let newActions = actions
                ? actions(id, meta, row)
                : [];

              if (deleteUrl) {
                const rep = deleteUrl(id, row);
                if(rep) {
                  const onClick = () => {
                    setDeleteOpen(1);
                    setDeleteActionUrl(rep);
                  }
                  newActions = [
                    ...newActions,
                    <IconButton
                        title={rep.title ?? "delete"}
                        color={rep.color ?? 'error'} onClick={onClick}>
                      {typeof rep.icon === 'function' ? rep.icon() : <Delete
                          />}
                    </IconButton>
                    ,
                  ];
                }
              }
              // if (forceDeleteUrl && loggedUser?.role?.name === 'super-admin') {
              //   const rep = forceDeleteUrl(id, row);
              //   if(rep) {
              //     const onClick = () => {
              //       setDeleteOpen(1);
              //       setDeleteActionUrl(rep);
              //     }
              //     newActions = [
              //       ...newActions,
              //       <IconButton
              //           title={rep.title ?? "delete"}
              //           color={rep.color ?? 'error'} onClick={onClick}>
              //         {typeof rep.icon === 'function' ? rep.icon() : <Delete
              //             />}
              //       </IconButton>
              //       ,
              //     ];
              //   }
              // }

              return <div className={"flex justify-start"}>{newActions}</div>;
            },
          },
        },
      ];
    }

      realColumns = realColumns.map((v) => {
        const label = <span className={'fw-bold text-uppercase'}>{v.label}</span>;
        if (v.options?.customBodyRender && data) {
          const render = v.options?.customBodyRender;
          const { options } = v;
          return {
            ...v,
            label,
            options: {
              ...options,
              customBodyRender: (value, tableMeta) => {
                const info =
                  data?.length > tableMeta.rowIndex
                    ? data[tableMeta.rowIndex]
                    : undefined;
                return info ? render(value, tableMeta, info) : data.rowIndex;
              },
              tableId,
            },
          };
        }
        return { ...v,label};
      });
    setTableId(uniqueId());
    setColumns0(realColumns);
  }, [columns, pk, deleteUrl, addRowNumber, actions, data]);

  const preSetData = (reqData) => {
    if (reqData) {
      reqData = Array.isArray(reqData.data) ? reqData : reqData.data;
      if (processData) {
        const { data, recordsFiltered, page } = processData(reqData);
        setError(null);
        setData(data);
        setCount(recordsFiltered);
        setPage(page);
      } else if(reqData.data) {
        setError(null);
        setData(reqData.data);
        setCount(reqData.recordsFiltered);
        setPage(reqData.page);
      }
    }
  };

  const fetchData = async (
    rows = undefined,
    newPage = undefined
  ) => {
    setLoading(true);
    const res = await xhrRequest(newPage ?? page, null, null, rows);
    preSetData(res);
    setLoading(false);
    return res;
  };

  const reload = () => {
    setChange((v) => v + 1);
  };

  useEffect(() => {
    setPage(0);
    if (tableState) {
      tableChange(tableState);
    } else {
      fetchData(undefined, 0).then();
    }
  }, [url, change, refresh]);

  // mock async function
  const xhrRequest = async (
    page,
    sortOrder = null,
    append = null,
    rows= undefined
  ) => {
    let isFiltered = false;

    if (filters0.length) {
      const filter = filters0.filter(
        (v) => v.value && v.value.trim().length > 0
      );
      if (filter?.length) {
        isFiltered = true;
        append = `${append ?? ""}&${filter
          ?.map(
            (v) =>
              `${encodeURIComponent(v.name)}=${encodeURIComponent(
                v.value ?? ""
              )}`
          )
          .join("&")}`;
      }
    }

    let char = url.includes("?") ? "&" : "?";
    const rows2 = rows ?? rowsPerPage ?? 0;
    const URL = `${url}${char}start=${(page ?? 0) * rows2}&length=${rows2}${append ? `&${append}` : ""
      }`;

    if (isFiltered) {
      emitFilterUrl?.(URL);
    }

    try {
        const isDoc = URL.includes("excel=1") || URL.includes("pdf=1");
        let config = {};
        if(isDoc){
            config = {...config,responseType: "blob",};
        }
      const { data } = await FetchDetails(URL, config);
      if(isDoc){
          const file = new Blob([data], {
              type: URL.includes("pdf=1") ? "application/pdf" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          saveAs(file, URL.includes("pdf=1") ? "report.pdf" : "excel.xlsx");
          return null;
      }
      setError(null);
      return data;
    } catch (err) {
      if (err.response) {
        setErrorStatus(err.response.status);
        setError(err.response.data?.toString());
      } else {
        setErrorStatus(500);
        setError("Connection refused");
      }
      setLoading(false);
      return null;
    }
  };

  const changePage = (page, sortOrder, append) => {
    setLoading(true);
    xhrRequest(page, sortOrder, append).then((res) => {
      setLoading(false);
      preSetData(res);
      setLoading(false);
      setSortOrder(sortOrder);
    });
  };

  const onTableChange = (action, tableState0) => {
    switch (action) {
      case "propsUpdate":{
          if(!tableState){
              setTableState(tableState0);
          }
        break;
      }
      case "search":
      case "sort":
      case "changePage":
        setTableState(tableState0);
        tableChange(tableState0);
        break;
    }
  };

  const tableChange = (tableState,newAppend) => {
    let cols = {};
    for (let o in columns0) {
      let obj = columns0[o];
      let n = obj.data ?? obj.name;
      if (n && obj?.options?.filter !== false) {
        let newObj = {
          data: n,
          label:obj.label,
          name:obj.name
        };
        if(obj.type){
          newObj['type'] = obj.type;
        }
        cols[n] = newObj;
      }
    }

    let object = {
      // columns: cols,
    };

    if (tableState.searchText) {
      object.search = tableState.searchText;
    }

    // if (tableState.sortOrder.name) {
    //   const obs = columns.find((v) => v.name === tableState.sortOrder.name);
    //   object.order = [
    //     {
    //       column:
    //         obs?.data ?? tableState.sortOrder.data ?? tableState.sortOrder.name,
    //       dir: tableState.sortOrder.direction,
    //     },
    //   ];
    // }

    let append = SerializeObject(object);

    if(newAppend){
      append += `&${newAppend}`;
    }

    changePage(tableState.page, tableState.sortOrder, append);
  };
  // const ExpandedRowsList=()=>{
  // return {}
  // }
  const options = {
    filter: false,
    filterType: "dropdown",
    viewColumns:true,
    responsive: "scroll",
    // responsive: "standard",
    enableNestedDataAccess: ".",
    customToolbarSelect: typeof customToolbarSelect === 'function' ? (arg)=>{
      return customToolbarSelect(arg.data.map(v=>data[v.dataIndex]));
    } : undefined,
    // selectableRowsHideCheckboxes:selectableRowsHideCheckboxes??false,
    elevation: elevation ?? 1,
    selectableRows,
    serverSide: true,
    count: count,
    onRowClick,
    download: false,
    print: false,
    page: page,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
      fetchData(numberOfRows).then();
    },
    rowsSelected,
    onRowSelectionChange,
    customToolbar: () => {
      const button = addButton ?? (onAddNew ? <span className={'ml-3'}><Button color={'success'} variant={'outlined'} size={"small"} onClick={onAddNew} startIcon={<Add/>}>Add</Button></span> : null);

      if(filters?.length>0){
        return <span>
          <span>
        <IconButton onClick={() => setFilter((prev) => !prev)}>
          <FilterList />
        </IconButton>
      </span>
          {button}
        </span>
      }

      return button;
    },
    rowsPerPageOptions: [10, 15, 30, 50, 70, 100],
    sortOrder: sortOrder,
    setTableProps: () => {
      return {
        // material ui v4 only
        size: "small",
      };
    },
    textLabels: {
      body: error
        ? {
          noMatch: (
            <div className={"text-left"}>
              <Alert
                severity="error"
                action={
                  <Button
                    endIcon={<Refresh />}
                    color="inherit"
                    onClick={() => fetchData()}
                  >
                    Refresh{" "}
                  </Button>
                }
              >
                <AlertTitle>Error ({errorStatus ?? ""})</AlertTitle>
                {error} — <strong>check it out!</strong>
              </Alert>
            </div>
          ),
        }
        : {
            noMatch: customEmptyLabel ?? "There is no pending list"
          },
    },
    onTableChange,
    expandableRows: ExpandedRows ? true : false,
    expandableRowsHeader: false,
    // expandableRowsOnClick: false,

    rowsExpanded: [0, 1, 2, 3, 4],
    renderExpandableRow: (rowData, rowMeta) => {
      return ExpandedRows(rowData, rowMeta);
    },
  };



  return (
    <div className={componentClass??"mt-3"}>
      {(filters0?.length > 0 || isLocationFilter) && filter && (
        <Card className="mb-3" sx={{ overflow: "visible" }}>
          <CardContent>
            {isLocationFilter && (
              <>
                {/*<div className="grid grid-cols-6 gap-4">*/}
                {/*  <div className="col-span-2 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Province</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Province"*/}
                {/*      options={provinces}*/}
                {/*      onChange={(e: any) => onProvinceChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedProvince &&*/}
                {/*        provinces.find((x: any) => x.value === selectedProvince.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">District</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select District"*/}
                {/*      options={districts}*/}
                {/*      onChange={(e:any) => onDistrictChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedDistrict &&*/}
                {/*        districts.find((x: any) => x.value === selectedDistrict.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Sector</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Sector"*/}
                {/*      options={sectors}*/}
                {/*      onChange={(e: any) => onSectorChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedSector &&*/}
                {/*        sectors.find((x: any) => x.value === selectedSector.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}

                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Cell</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Cell"*/}
                {/*      options={cells}*/}
                {/*      onChange={(e: any) => onCellChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedCell && cells.find((x: any) => x.value === selectedCell.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}

                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Village</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Village"*/}
                {/*      options={villages}*/}
                {/*      onChange={(e: any) => onVillageChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedVillage &&*/}
                {/*        villages.find((x: any) => x.value === selectedVillage.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <button className={'flex mt-6 px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'} onClick={resetLocation}>Reset</button>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <hr className="my-4" />
              </>
            )}

            <div className="row">
              {filters0.map((v) => (
                <div className="col-lg-3 col-md-4">
                  <label className="block mb-1 text-sm fw-bold">
                    {v.label}
                  </label>
                  {v.type === "select" ? (
                    <Select
                      // onBlur={reload}
                      onChange={(e) => {
                        v.value = e?.value;
                        setFilters0([...filters0]);
                        reload();
                      }}
                      value={
                        !v.value
                          ? null
                          : v.values?.find((item) => item.value === v.value)
                      }
                      options={v.values?.map(({ label, value }) => ({
                        value,
                        label,
                      }))}
                    />
                  ) : (
                    <input
                      onBlur={reload}
                      placeholder={v.label}
                      className="form-control"
                      value={v.value}
                      type={v.type ?? 'text'}
                      onChange={(e) => {
                        v.value = e.target.value;
                        setFilters0([...filters0]);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          reload();
                          // e.currentTarget.blur();
                        }
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
            {filters0.filter(v=>v.value).length>0 && <div className={'mt-3 clearfix'}>
              <Button variant={'contained'} className={'float-end'} color={'error'} onClick={()=>{
                setFilters0(filters=>filters.map(v=>({...v,value:null})));
              }}>Clear</Button>
            </div>}
          </CardContent>
        </Card>
      )}
      {deleteActionUrl ? (
        <DeleteDialog
            {...props}
          open={deleteOpen}
          onSuccess={reload}
            usePost={deleteActionUrl?.post}
          setOpen={setDeleteOpen}
            buttonTitle={deleteActionUrl?.title}
            title={deleteActionUrl?.title}
          body={typeof deleteActionUrl === 'string' ? undefined : deleteActionUrl?.body}
          url={typeof deleteActionUrl === 'string' ? deleteActionUrl : deleteActionUrl?.url}
        />
      ) : null}
      <div className={"position-relative"}>
        {loading ? (
          <div
            className={
              "position-absolute h-100 w-100 top-0 start-0 d-flex align-items-center justify-content-center"
            }
          >
            <div
              className={"bg-white p-3 rounded-4 shadow-sm text-center"}
              style={{ zIndex: 20000 }}
            >
              <div>
                <CircularProgress color={"success"} />
              </div>
              <span>
                <Typography>Loading ....</Typography>
              </span>
            </div>
          </div>
        ) : null}
        <MUIDataTable
          title={
            (
              <div className={titleClass ?? "py-4"}>
                {!exportButtons && (typeof title === "string" ? (
                  <Typography variant="h6" className="text-uppercase">{title}</Typography>
                ) : (
                  title
                ))}
                {exportButtons && <div>
                  <Button onClick={()=>{
                    tableChange(tableState,"pdf=1");
                  }} size={'small'} variant={'contained'} className={'me-2'} startIcon={<PictureAsPdf/>}>PDF</Button>
                  <Button onClick={()=>{
                    tableChange(tableState,"excel=1");
                  }} size={'small'} variant={'contained'} className={'me-2'} startIcon={<FileCopy/>}>Excel</Button>
                  {/*<Button onClick={()=>{*/}
                  {/*  tableChange(tableState,"excel=1");*/}
                  {/*}} size={'small'} variant={'contained'} startIcon={<FileCopy/>}>CSV</Button>*/}
                </div>}
              </div>
            ) ?? (
              <Typography variant="h6" >
                List
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                )}
              </Typography>
            )
          }
          data={originData??data}
          columns={columns0}
          options={options}
        />
      </div>
    </div>
  );
};


export default connect(mapStateToProps,mapDispatchToProps)(DatatableComponent);
