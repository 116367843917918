import React, {useEffect} from "react";
import {downloadBase64, downloadBase64Get} from "../store/helpers/common_functions";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {Container} from "react-bootstrap";
import { getFilePlugin } from '@react-pdf-viewer/get-file';


const FullScreenViewer = ()=>{
    const [loading,setLoading] = React.useState(false);
    const [base64,setBase64] = React.useState();
    const {path} = useParams();
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `a-copy-of-${fileName}`;
        },
    });
    const { DownloadButton } = getFilePluginInstance;

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Print,
                    ShowSearchPopover,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <div style={{ padding: '0px 2px' }}>
                            <ShowSearchPopover />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomOut />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <Zoom />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <GoToPreviousPage />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <CurrentPageInput /> / <NumberOfPages />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <GoToNextPage />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <DownloadButton />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <Print />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );


    const defaultLayoutPluginInstance = defaultLayoutPlugin(
    //     {
    //     renderToolbar,
    // }
    );

    const downloadBase = async ()=>{
        setLoading(true);
        const base = await downloadBase64Get(path,"application/pdf");
        setLoading(false);
        if(base) {
            setBase64(base);
        }else{

        }
    }

    useEffect(()=>{
        if(path){
            downloadBase().then();
        }
    },[path]);


    return <Container>
        {loading ? <div className={'text-center p-5 m-5'} style={{minHeight: "200px"}}>
            <CircularProgress/>
        </div> : base64 ?
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={base64}
                        plugins={[
                            // Register plugins
                            defaultLayoutPluginInstance,
                            getFilePluginInstance,
                        ]}/>
            </Worker> : null}
    </Container>
}

export default FullScreenViewer;