import React, {useMemo} from 'react'
import {default as ReactSelect} from "react-select";
import countryList from 'react-select-country-list'



function CountrySelector(props) {
    const options = useMemo(() => countryList().getData(), [])

    const option = options.map((v) => ({label: v.label, id: v.label}));

    return <ReactSelect {...props} options={option}
                        value={typeof props.value === 'string' ? option.find((v) => v.id === props.value) : props.value}/>
}

export default CountrySelector
