import React from "react";
import ReactDOM from "react-dom/client";

//scss
import "./assets/scss/hope-ui.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/customizer.scss"
import reportWebVitals from "./reportWebVitals";

//router
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
//store
import { Provider } from "react-redux";

import Index from "./views/index";
import { IndexRouters } from "./router";
import { SimpleRouter } from "./router/simple-router";
import { DefaultRouter } from "./router/default-router";
import {configureStore} from "./store/configureStore";
import axios from "axios";
import {errorInterceptor} from "./store/helpers/common_functions";
import Default from "./layouts/dashboard/default";
import SignInLayout from "./router/sign-in-layout";
import LockScreen from "./views/dashboard/auth/lock-screen";
import FullScreenViewer from "./components/full_screen_viewer";
import ChangePasswordScreen from "./views/dashboard/auth/change-password-screen";



axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}v1/admin/`;

axios.interceptors.response.use((e)=>e, errorInterceptor);

const store = configureStore();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      {/*<App>*/}
        <BrowserRouter>
        <Routes>
          <Route path="/">
              <Route path={''} element={<Navigate to={'dashboard'}/>}/>
          {
            DefaultRouter.map(v=><Route path={v.path} element={v.element}>
                {v.children.map(item=><Route path={item.path} element={item.element}/>)}
            </Route>)
          }
          {
            IndexRouters.map(v=><Route path={v.path} element={v.element}/>)
          }
              <Route path={'full/:path'} element={<FullScreenViewer />}/>
          <Route path={"/"} element={<SignInLayout/>}>
              <Route path={'reset-password'} element={<LockScreen />}/>
          </Route>
              <Route path="auth/" element={<SignInLayout/>}>
              <Route path="change-password" element={<ChangePasswordScreen/>}/>
          {
            SimpleRouter.map(v=><Route path={v.path} element={v.element}/>)
          }
              </Route>
          </Route>
        </Routes>
        </BrowserRouter>
      {/*</App>*/}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
