
import React, {useEffect} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, Block, BorderColor, VerifiedUser} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {inArray} from "../../../store/helpers/common_functions";
const DecisionStatuses = (props) => {
    const {loggedUser} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }





    return <>
        <AddForm setOpen={handleClose} method={id ? 'PUT' : 'POST'} title={id ? 'Update Decision Status' : 'Create New Decision Status'} url={id ? `decisions-status/update/${id}` : 'decisions-status/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name_en",
                        label:"Name(English)",
                        required:true,
                        value:info?.name_en,
                    },
                    {
                        name:"name_rw",
                        label:"Name (Kinyarwanda)",
                        value:info?.name_rw,
                        required:true
                    },
                    {
                        name:"name_fr",
                        label: "Name (French)",
                        value:info?.name_fr,
                        required:true
                    },
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DatatableComponent forceDeleteUrl={(id )=>({
            url:`decisions-status/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        })} deleteUrl={inArray(loggedUser?.permissions,"decisions-status-delete") ?(id,info)=>({
            url:`decisions-status/action/single`,
            post:true,
            icon:()=>{
                return info?.status === 0 ? <VerifiedUser /> : <Block/>
            },
            color:info?.status === 0 ? 'success' : undefined,
            title:info?.status === 0 ? 'activate' : 'deactivate',
            body:{
                id,
                action:info?.status === 0 ? "active" : "block"
            }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"decisions-status-create") ? ()=>{
            setOpen(1);
        } : undefined} actions={(id, _meta,info)=>[
            inArray(loggedUser?.permissions,"decisions-status-update") && <IconButton onClick={() => {
                setOpen(2);
                setId(id);
                setInfo(info);
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"Name",
                name:"name_en"
            },
            {
                label:"Status",
                name:"status",
                options: {
                    customBodyRender:(v)=><Chip size={'small'} color={v===0 ? 'error' : 'success'} label={v === 0 ? 'Disabled' : 'Active'}/>
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Decision Statuses'} url={`decisions-status/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(DecisionStatuses);
