import {Box, Typography} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

 function TabPanel(props) {
    const { children, value, index,className,p, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: p ?? 3 }} className={className ?? 'bg-blueGray-50'}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

 export default TabPanel;