import React, {useEffect, useState} from 'react'
import DatatableComponent from "../../../../helpers/DatatableComponent";
import moment from "moment";
import {Accessibility, Add, BorderColor, Mail, RemoveRedEye} from "@mui/icons-material";
import {Button, Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {useNavigate} from "react-router-dom";
import Status from "../../../../components/partials/status";
import {defaultFilters, inArray} from "../../../../store/helpers/common_functions";
import SubmitApplication from "../../../../components/partials/submit_application";
import PublishStatus from "../../../../components/partials/publish_status";
import DeleteDialog from "../../../../helpers/DeleteDialog";
const DecisionDocuments = (props) => {
    const {loggedUser} = props;
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [ids, setIds] = useState([]);
    const [openSendMail, setOpenSendMail] = useState(false);
    const navigate = useNavigate();
    // const handleClose = ()=>{
    //     setOpen(0);
    //     setId(null);
    //     setInfo(null);
    // }

    const closeModal=()=>{
        setIds([]);
    }

    const reload = ()=>setChange(v=>v+1);


    return <>
        <DeleteDialog info={true} buttonTitle={'Send Notifications'} open={ids.length>0} setOpen={closeModal} onSuccess={()=>{
            closeModal();
            reload();
        }} body={{
            ids,
            action:"send-mail"
        }} url={'documents/action/multiple'} usePost={true} title={'Bulk Notification'} bodyText={'Are you sure you want to send bulk notifications to selected decision ?'} {...props}/>
        <DatatableComponent customToolbarSelect={(arg)=><span className={'me-2'}><Button variant={'outlined'} startIcon={<Mail/>} size={'sm'} color={'success'} onClick={()=>{
            setIds(arg.map(v=>v.id));
            console.log(arg);
        }}>Send Notifications</Button></span>} selectableRows={openSendMail ? 'multiple' : undefined} filters={defaultFilters} deleteUrl={inArray(loggedUser?.permissions,"documents-delete") ? (id)=>({
            url:`documents/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        }) : undefined} addRowNumber={true} addButton={<span>
            {inArray(loggedUser?.permissions,"documents-create") && <span className={'ms-2'}><Button onClick={()=>{
                navigate('/dashboard/decisions/create');
            }} size={'small'} startIcon={<Add/>} color={'success'} variant={'outlined'}>Add</Button></span>}
            {<span className={'ms-2'}><Button onClick={()=>{
                setOpenSendMail(true);
            }} size={'small'} startIcon={<Mail/>} color={'success'} variant={'outlined'}>Notify</Button></span>}
        </span>} actions={(id,_meta,data)=>[
            <IconButton onClick={() => {
                navigate(`/dashboard/decisions/details/${id}`);
            }}>
                <RemoveRedEye />
            </IconButton>,
            (data?.status_publish === 3 || inArray([0,1,3],data?.status)) && inArray(loggedUser?.permissions,"documents-update") && <IconButton onClick={() => {
                navigate(`/dashboard/decisions/update/${id}`);
            }}>
                <BorderColor />
            </IconButton>,
            inArray([0,1],data?.status) && <SubmitApplication {...props} info={data} size={'small'} onSuccess={reload}/>

        ]} elevation={1} columns={[
            {
                label:"Case Name",
                name:"name"
            },
            {
                label:"Case Number",
                name:"case_no"
            },
            {
                label:"Collection",
                name:"collection.name_en"
            },
            {
                label:"Neutral citation",
                name:"neutral_citation"
            },
            {
                label:"Publish Date",
                name:"date",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            },
            {
                label:"Decision",
                name:"decisionsStatus.name_en",
                options:{
                    customBodyRender:(v,_meta)=><Chip label={v} color={v === 'Unreported' ? 'warning' : 'info'} size={'small'}/>
                }
            },
            {
                label:"Status",
                name:"status_publish",
                options:{
                    customBodyRender:(v,_meta,info)=><Status status={v} info={info}/>
                }
            }
        ]} title={'Decision Documents'} url={`documents/table?change=${change}&section=2.1`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(DecisionDocuments);
