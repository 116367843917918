import ReCAPTCHA from "react-google-recaptcha";
import AddForm from "../helpers/AddForm";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const ResetPasswordForm = ({loggedUser,inline,token,email}) => {
    const [captcha, setCaptcha] = useState(undefined);
    const navigate = useNavigate();
  return <AddForm customSuccessMessage={'Your password has been reset successfully , please Log in to continue'}  open={true} inline={inline} setOpen={()=>false} onSuccess={(res)=>{
      if(loggedUser) {
          localStorage.setItem("user", JSON.stringify({
              ...loggedUser,
              password_reset: false
          }))
          window.location.reload();
      }else{
          navigate('/auth/sign-in');
      }
  }} url={'auth/reset-password'} title={'Reset Password'} mainFields={[
      {
          fields:[
              {
                  name:"email",
                  type:"email",
                  label:"Email Address",
                  value:email,
                  required:true,
                  disabled:email !== undefined && email !== null,
              },
              {
                  name:"token",
                  type:"hidden",
                  label:"Token",
                  value:token,
                  hidden:true,
                  required:true,
              },
              {
                  name:"password",
                  type:"password",
                  label:"New Password",
                  required:true,
              },
              {
                  name:"captcha",
                  type:"captcha",
                  label:"I'm not A robot",
                  // required:true,
                  value:captcha,
                  render:()=>{
                      return <div>
                          <ReCAPTCHA
                              sitekey="6LeLwB0lAAAAAHjH_Ve0hjkissvnjPADgLa5JSkP"
                              onChange={(v)=>{
                                  setCaptcha(v);
                              }}
                          />
                      </div>
                  }
              },
          ]
      }
  ]}/>
}

export default ResetPasswordForm;