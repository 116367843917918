import {connect} from 'react-redux';
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import AddForm from "../../../helpers/AddForm";
import {useEffect, useState} from "react";
import PageLoading from "../../../components/PageLoading";
import {Button} from "react-bootstrap";
import moment from "moment";
const JudiciaryReport = ({FetchDetails,loggedUser})=>{
    const m = moment();
    const [endDate, setEndDate] = useState(m.format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(m.subtract(30,'days').format("YYYY-MM-DD"));
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const loadInfo = async ()=>{
        setLoading(true);
        const resp = await FetchDetails(`reports/judiciary?startDate=${startDate}&endDate=${endDate}`);
        setLoading(false);
        if(resp.status){
            setInfo(resp.data.data);
        }
    }

    useEffect(() => {
        if(startDate && endDate){
            loadInfo().then();
        }
    }, [startDate,endDate]);

    // Quick and simple export target #table_id into a csv
    function download_table_as_csv(table_id, separator = ',') {
        // Select rows from table_id
        var rows = document.querySelectorAll('#' + table_id + ' tr');
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll('td, th');
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join('\n');
        // Download it
        var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(()=>setDownloading(false),3000);
    }

    useEffect(() => {
        if(downloading){
            download_table_as_csv("datatable");
        }
    }, [downloading]);

    return <div style={{color: "#3a3a3a"}}>
        <AddForm hideSubmitButton={true} inline={true} col={6} title={"Generate Judiciary Report"} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"startDate",
                        label:"Start Date",
                        type:"date",
                        value:startDate,
                        setValue:setStartDate
                    },
                    {
                        name:"endDate",
                        label:"End Date",
                        type:"date",
                        value: endDate,
                        setValue:setEndDate
                    }
                ]
            }
        ]}/>

        {loading || !info ? <PageLoading error={!loading ? 'Loading info Error' : null}/> : <div className={'card'}>
            <div className={'card-body'}>
                <div className={'clearfix mb-2'}>
                    <div className={'float-end'}>
                        <Button size={'sm'} onClick={()=>setDownloading(true)}>Generate CSV Report</Button>
                    </div>
                </div>
                <div className={'table-responsive'}>
                    <table className={'table table-striped'} id={"datatable"}>
                        <thead>
                        <tr>
                            <th>CASE LAWS</th>
                            <th>Titles of created Case laws</th>
                            <th>Under review</th>
                            <th>Pending due to requests for action</th>
                            <th>Law reporter per month, Trimester
                                Semester
                                Year
                            </th>
                            {info["2.2.2"].collections.map(v=><th>
                                {v.name}
                            </th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            [
                                {section:"2.1",label:"Decisions"},
                                {section:"2.2.1",label:"Law Reports"},
                                {section:"2.2.2",label:"Court precedents"},
                                {section:"2.2.3",label:"Scholarly materials"},
                            ].map(v=>  {
                                const item = info[v.section];
                                const reported = item['reported'];
                                return reported ? <>
                                    <tr>
                                        <td rowSpan={2}>{v.label}</td>
                                        <td>Reported</td>
                                        <td>{reported['review']}</td>
                                        <td>{reported['rejected']}</td>
                                        <td>{loggedUser?.name} ({startDate} - {endDate})</td>
                                        {reported.collections.map(v=><td>
                                            {v.count}
                                        </td>)}
                                    </tr>
                                    <tr>
                                        {downloading && <td>{v.label}</td>}
                                        <td>Unreported</td>
                                        <td>{item['unreported']['review']}</td>
                                        <td>{item['unreported']['rejected']}</td>
                                        <td>{loggedUser?.name} ({startDate} - {endDate})</td>
                                        {item['unreported'].collections.map(v=><td>
                                            {v.count}
                                        </td>)}
                                    </tr>
                                </> : <>
                                    <tr>
                                        <td>{v.label}</td>
                                        <td></td>
                                        <td>{item['review']}</td>
                                        <td>{item['rejected']}</td>
                                        <td>{loggedUser?.name} ({startDate} - {endDate})</td>
                                        {item.collections.map(v=><td>
                                            {v.count}
                                        </td>)}
                                    </tr>
                                </>;
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>}
    </div>
}


export default connect(mapStateToProps,mapDispatchToProps)(JudiciaryReport);