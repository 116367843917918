import React from 'react'
import Index from '../views/dashboard/index'
// import { Switch, Route } from 'react-router-dom'
// user
import UserProfile from '../views/dashboard/app/user-profile';
import UserAdd from '../views/dashboard/app/user-add';
import UserList from '../views/dashboard/app/user-list';
// import userProfileEdit from '../views/dashboard/app/user-privacy-setting';
// widget
import Widgetbasic from '../views/dashboard/widget/widgetbasic';
import Widgetcard from '../views/dashboard/widget/widgetcard';
import Widgetchart from '../views/dashboard/widget/widgetchart';
// icon
import Solid from '../views/dashboard/icons/solid';
import Outline from '../views/dashboard/icons/outline';
import DualTone from '../views/dashboard/icons/dual-tone';
// Form
import FormElement from '../views/dashboard/from/form-element';
import FormValidation from '../views/dashboard/from/form-validation';
import FormWizard from '../views/dashboard/from/form-wizard';
// table
import BootstrapTable from '../views/dashboard/table/bootstrap-table';
import TableData from '../views/dashboard/table/table-data';

// map
import Vector from '../views/dashboard/maps/vector';
import Google from '../views/dashboard/maps/google';

//extra
// import PrivacyPolicy from '../views/dashboard/extra/privacy-policy';
// import TermsofService from '../views/dashboard/extra/terms-of-service';

//TransitionGroup
// import { TransitionGroup, CSSTransition } from "react-transition-group";
//Special Pages
import Kanban from '../views/dashboard/special-pages/kanban';
import Pricing from '../views/dashboard/special-pages/pricing';
import Timeline from '../views/dashboard/special-pages/timeline';
import Calender from '../views/dashboard/special-pages/calender';
import RtlSupport from '../views/dashboard/special-pages/RtlSupport'

//admin
import Admin from '../views/dashboard/admin/admin';
import Default from '../layouts/dashboard/default';
import Logs from "../views/dashboard/admin/logs";
import Categories from "../views/dashboard/admin/categories";
import Institutions from "../views/dashboard/admin/institutions";
import Volumes from "../views/dashboard/admin/volumes";
import Sections from "../views/dashboard/admin/sections";
import SubCategories from "../views/dashboard/admin/sub-categories";
import Collection from "../views/dashboard/admin/collection";
import Cases from "../views/dashboard/admin/cases";
import Judges from "../views/dashboard/admin/judges";
import Relations from "../views/dashboard/admin/relation";
import DecisionTypes from "../views/dashboard/admin/decision-types";
import DecisionStatuses from "../views/dashboard/admin/decision-status";
import InForceDocuments from "../views/dashboard/admin/docs/in-force-documents";
import InForceCreate from "../views/dashboard/admin/docs/in-force-create";
import NotInForceCreate from "../views/dashboard/admin/docs/not-in-force-create";
import NotInForceDocuments from "../views/dashboard/admin/docs/not-in-force-documents";
import DecisionDocuments from "../views/dashboard/admin/docs/decision-documents";
import DecisionCreate from "../views/dashboard/admin/docs/decision-create";
import ResourceDocuments from "../views/dashboard/admin/docs/resource-documents";
import LawReportCreate from "../views/dashboard/admin/docs/law-report-create";
import CourtLegalPositionCreate from "../views/dashboard/admin/docs/court-legal-position-create";
import ScholarlyMaterialsCreate from "../views/dashboard/admin/docs/scholarly-materials-create";
import LawReportDetails from "../views/dashboard/admin/docs/law-report-details";
import ViewDocument from "../views/dashboard/admin/view-document";
import CourtLegalDetails from "../views/dashboard/admin/docs/court-legal-details";
import ScholarlyMaterialDetails from "../views/dashboard/admin/docs/scholarly-material-details";
import DecisionDetails from "../views/dashboard/admin/docs/decision-details";
import InForceDocDetails from "../views/dashboard/admin/docs/in-force-doc-details";
import Roles from "../views/dashboard/admin/roles";
import Visitors from "../views/dashboard/admin/visitors";
import Dashboard from "../views/dashboard/index";
import PdfCrop from "../views/dashboard/admin/pdf_crop";
import NotInForceDocDetails from "../views/dashboard/admin/docs/not-in-force-doc-details";
import JudiciaryReport from "../views/dashboard/admin/judiciary-report";
import RawReformReport from "../views/dashboard/admin/raw-reform-report";


export const DefaultRouter = [
    {
        path: 'dashboard/',
        element: <Default />,
        children: [
            {
                path: '',
                element: <Dashboard/>
            },
            {
                path: 'logs',
                element: <Logs />
            },
            {
                path: 'crop',
                element: <PdfCrop />
            },
            {
                path: 'judiciary_report',
                element: <JudiciaryReport />
            },
            {
                path: 'reform_report',
                element: <RawReformReport />
            },
            {
                path: 'categories',
                element: <Categories />
            },
            {
                path: 'sub-categories',
                element: <SubCategories />
            },
            {
                path: 'institutions',
                element: <Institutions />
            },
            {
                path: 'collection',
                element: <Collection />
            },
            {
                path: 'cases',
                element: <Cases />
            },
            {
                path: 'judges',
                element: <Judges />
            },
            {
                path: 'relations',
                element: <Relations />
            },
            {
                path: 'decisions-types',
                element: <DecisionTypes />
            },
            {
                path: 'decisions-status',
                element: <DecisionStatuses />
            },
            {
                path: 'sections',
                element: <Sections />
            },
            {
                path: 'resources',
                element: <ResourceDocuments />
            },
            {
                path: 'law-report/create',
                element: <LawReportCreate />
            },
            {
                path: 'law-report/update/:id',
                element: <LawReportCreate />
            },
            {
                path: 'law-report/details/:id',
                element: <LawReportDetails />
            },
            {
                path: 'court-legal/details/:id',
                element: <CourtLegalDetails />
            },
            {
                path: 'scholar-material/details/:id',
                element: <ScholarlyMaterialDetails />
            },
            {
                path: 'decisions/details/:id',
                element: <DecisionDetails />
            },
            {
                path: 'in-force/details/:id',
                element: <InForceDocDetails />
            },
            {
                path: 'not-in-force/details/:id',
                element: <NotInForceDocDetails />
            },
            {
                path: 'view/document/:id',
                element: <ViewDocument />
            },
            {
                path: 'court-legal/create',
                element: <CourtLegalPositionCreate />
            },
            {
                path: 'court-legal/update/:id',
                element: <CourtLegalPositionCreate />
            },
            {
                path: 'scholar-material/create',
                element: <ScholarlyMaterialsCreate />
            },
            {
                path: 'scholar-material/update/:id',
                element: <ScholarlyMaterialsCreate />
            },
            {
                path: 'volumes',
                element: <Volumes />
            },
            {
                path: 'in-force-documents',
                element: <InForceDocuments />
            },
            {
                path: 'in-force/create',
                element: <InForceCreate />
            },
            {
                path: 'in-force/update/:id',
                element: <InForceCreate />
            },
            {
                path: 'not-in-force',
                element: <NotInForceDocuments />
            },
            {
                path: 'not-in-force/create',
                element: <NotInForceCreate />
            },
            {
                path: 'not-in-force/update/:id',
                element: <NotInForceCreate />
            },
            {
                path: 'decisions',
                element: <DecisionDocuments />
            },
            {
                path: 'decisions/create',
                element: <DecisionCreate />
            },
            {
                path: 'decisions/update/:id',
                element: <DecisionCreate />
            },
            {
                path: 'special-pages/calender',
                element: <Calender />
            },
            {
                path: 'special-pages/kanban',
                element: <Kanban />
            },
            {
                path: 'special-pages/pricing',
                element: <Pricing />
            },
            {
                path: 'special-pages/timeline',
                element: <Timeline />
            },
            {
                path: 'special-pages/rtl-support',
                element: <RtlSupport/>,
            },
            {
                path: 'app/user-profile',
                element: <UserProfile />
            },
            {
                path: 'app/user-add',
                element: <UserAdd />
            },
            {
                path: 'app/user-list',
                element: <UserList />
            },
            {
                path: 'admin',
                element: <Admin />
            },
            {
                path: 'visitors',
                element: <Visitors />
            },
            {
                path: 'roles',
                element: <Roles />
            },
            // Widget
            {
                path: 'widget/widgetbasic',
                element: <Widgetbasic />
            },
            {
                path: 'widget/widgetchart',
                element: <Widgetchart />
            },
            {
                path: 'widget/widgetcard',
                element: <Widgetcard />
            },
            // Map
            {
                path: 'map/google',
                element: <Google />
            },
            {
                path: 'map/vector',
                element: <Vector />
            },
            // Form
            {
                path: 'form/form-element',
                element: <FormElement />
            },
            {
                path: 'form/form-wizard',
                element: <FormWizard />
            },
            {
                path: 'form/form-validation',
                element: <FormValidation />
            },
            // Table
            {
                path: 'table/bootstrap-table',
                element: <BootstrapTable />
            },
            {
                path: 'table/table-data',
                element: <TableData />
            },
            // Icon
            {
                path: 'icon/solid',
                element: <Solid />
            },
            {
                path: 'icon/outline',
                element: <Outline />
            },
            {
                path: 'icon/dual-tone',
                element: <DualTone />
            }
        ]
    }
]
// const DefaultRouter = () => {
//     return (
//         <TransitionGroup>
//             <CSSTransition classNames="fadein" timeout={300}>
//                 <Switch>
//                     <Route path="/dashboard" exact component={Index} />
//                     {/* user */}
//                     <Route path="/dashboard/app/user-profile"     exact component={UserProfile} />
//                     <Route path="/dashboard/app/user-add"         exact component={UserAdd}/>
//                     <Route path="/dashboard/app/user-list"        exact component={UserList}/>
//                     <Route path="/dashboard/app/user-privacy-setting" exact component={userProfileEdit}/>
//                      {/* widget */}
//                      <Route path="/dashboard/widget/widgetbasic"   exact component={Widgetbasic}/>
//                      <Route path="/dashboard/widget/widgetcard"    exact component={Widgetcard}/>
//                      <Route path="/dashboard/widget/widgetchart"   exact component={Widgetchart}/>
//                      {/* icon */}
//                      <Route path="/dashboard/icon/solid"           exact component={Solid}/>
//                      <Route path="/dashboard/icon/outline"         exact component={Outline}/>
//                      <Route path="/dashboard/icon/dual-tone"       exact component={DualTone}/>
//                      {/* From */}
//                      <Route path="/dashboard/form/form-element"    exact component={FormElement}/>
//                      <Route path="/dashboard/form/form-validation" exact component={FormValidation}/>
//                      <Route path="/dashboard/form/form-wizard"     exact component={FormWizard}/>
//                      {/* table */}
//                      <Route path="/dashboard/table/bootstrap-table" exact component={BootstrapTable}/>
//                      <Route path="/dashboard/table/table-data"      exact component={TableData}/>
//                      {/*special pages */}
//                      <Route path="/dashboard/special-pages/billing" exact component={Billing}/>
//                      <Route path="/dashboard/special-pages/kanban" exact component={Kanban}/>
//                      <Route path="/dashboard/special-pages/pricing" exact component={Pricing}/>
//                      <Route path="/dashboard/special-pages/timeline" exact component={Timeline}/>
//                      <Route path="/dashboard/special-pages/calender" exact component={Calender}/>
//                      {/* map */}
//                      <Route path="/dashboard/map/vector" exact component={Vector}/>
//                      <Route path="/dashboard/map/google" exact component={Google}/>
//                      {/* extra */}
//                      <Route path="/dashboard/extra/privacy-policy" exact component={PrivacyPolicy}/>
//                      <Route path="/dashboard/extra/terms-of-service" exact component={TermsofService}/>
//                      {/*admin*/}
//                      <Route path="/dashboard/admin/admin" exact component={Admin}/>
//                 </Switch>
//             </CSSTransition>
//         </TransitionGroup>
//     )
// }

// export default DefaultRouter
