import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import JoditEditor from "jodit-react";
import DialogContent from "@mui/material/DialogContent";
import { FileUploader } from "react-drag-drop-files";
import { default as ReactSelect } from "react-select";
import CountrySelector from './CountrySelector';
import {connect} from "react-redux";
import {
  Alert,
  CircularProgress,
  DialogActions,
  DialogTitle,
  FormControl, IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Card } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import {useEffect, useState} from "react";
import moment from "moment";
import { toast } from "react-toastify";
import ListTile from "./ListTile";
import {getError, getSuccessMessage} from "../store/helpers/common_functions";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import MultiAddTextField from "../components/multiaddtextfield";
import MultipleSelectCheckmarks from "../components/multi-select";
import PasswordField from "../components/password_field";
import {Search} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import MultiSelectSearch from "../components/multi-select-search";

const cheerio = require("cheerio");
const fileTypes = ["PDF", "JPG", "JPEG", "PNG", "GIF"];

export function CheckBoxItem(props) {
  const { item,setValue,value,label } = props;

  let val = item.values ?? [];
  const [list, setList] = useState(val);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setList(val);
  }, [item.values]);


  const col = item.checkCol ?? 6;

  return (
    <div>
      <div className={'d-flex'}>
        <div className={'flex-grow-1 me-2'}>{showSearch ? <input value={search} onChange={e=>setSearch(e.target.value)} type={"search"} className={'form-control form-control-sm'} placeholder={'Search Here'}/> : label}</div>
        <IconButton onClick={()=>{
          setShowSearch(prev=>!prev);
        }}>
          <Search/>
        </IconButton>
        <Checkbox checked={value?.length  === list?.length} onChange={(e)=>{
          if(e.target.checked) {
            setValue(list.map(v => v.value));
          }else {
            setValue([]);
          }
        }}/>
      </div>
      <div className={"row"}>
        {list.filter(v=> !search || v.label.toLowerCase().indexOf(search.toLowerCase())>-1).map((v) => (
            <div className={`col-md-${col} col-md-${col}`}>
              <ListTile
                  selected={value?.filter((i) => i === v.value)?.length > 0}
                  handleToggle={(it) => {
                    if (setValue && typeof setValue === "function") {
                      if (value?.filter((i) => i === v.value)?.length > 0) {
                        setValue(value.filter((i) => i !== v.value));
                      } else if (value?.length) {
                        value.push(v.value);
                        setValue([...value]);
                      } else {
                        setValue([v.value]);
                      }
                    }
                  }}
                  item={{ label: v.label }}
              />
            </div>
        ))}
      </div>
    </div>
  );
}

// export type FieldType =
//   | "tel"
//   | "email"
//   | "country"
//   | "textarea"
//   | "text"
//   | "select"
//   | "editor"
//   | "file"
//   | "file-drag"
//   | "switch"
//   | "date"
//   | "datetime-local"
//   | "checkbox"
//   | "name"
//   | "time"
//   | "number";
//
// export type ValueItem = { label?: string; id: string; name?: string };
// export type MainFields = { title; fields: Array<FieldItem> };
//
// export type FieldItem = {
//   name: string;
//   label?: string;
//   value?;
//   type?: FieldType | undefined | null;
//   required?: boolean | undefined;
//   hidden?: boolean | undefined;
//   setValue?: (val) => void | undefined | null;
//   uploadUrl?: string | undefined | null;
//   fileKey?: string | undefined | null;
//   submitAsString?: boolean | undefined | null;
//   multiple?: boolean | undefined | null;
//   disabled?: boolean | undefined | null;
//   path?: string | undefined | null;
//   dir?: string | undefined | null;
//   minLength?: number | undefined | null;
//   maxLength?: number | undefined | null;
//   min?: number | undefined | null;
//   max?: number | undefined | null;
//   maxDate?: Moment | undefined | null;
//   minDate?: Moment | undefined | null;
//   validate?: (val, values?, params?) => boolean | string;
//   render?: () => ReactJSXElement;
//   col?: number | undefined;
//   id?: string | undefined;
//   placeholder?: string | undefined | null;
//   rows?: number | undefined | null;
//   values?: Array<ValueItem> | undefined | null;
//   fileTypes?: Array<any> | undefined | null;
//   nextType?: string;
// };
//
// type IProps = {
//   open?: number | undefined;
//   formAlert?;
//   setOpen?: (status: number) => void;
//   beforeSubmit?: (data) => boolean;
//   title?: string | undefined;
//   mainFields: Array<MainFields>;
//   url: string;
//   backPath?: string | undefined;
//   onSuccess: (data) => void;
//   col?: number | undefined;
//   maxWidth?: Breakpoint | undefined;
//   useMaterial?: boolean | undefined;
//   buttonColor?:
//     | "inherit"
//     | "primary"
//     | "secondary"
//     | "success"
//     | "error"
//     | "info"
//     | "warning"
//     | undefined;
//   buttonTitle?: string | undefined;
//   method?: "POST" | "PUT" | "DELETE" | "GET" | undefined;
//   inline?: boolean | undefined;
//   locationFields?: boolean | undefined;
//   footer?: ReactJSXElement | undefined;
//   appendForm?: ReactJSXElement | undefined;
//   hideSubmitButton?: boolean;
// };

const AddForm = (props)=> {
  const {
    open,
    formAlert,
    setOpen,
    title,
    mainFields,
    url,
    onSuccess,
    customSuccessMessage,
    backPath,
    FetchDetails,
    elevation,
    col,
    maxWidth,
    useMaterial,
    buttonColor,
    buttonTitle,
    cancelLabel,
    method,
    responseType,
    inline,
    footer,
    locationFields,
    appendForm,
    hideSubmitButton,
    beforeSubmit,
    SubmitGeneralForm,
  } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm();

  const [fieldElement, setFieldElement] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [provinceId, setProvinceId] = React.useState();
  const [districtId, setDistrictId] = React.useState();
  const [sectorId, setSectorId] = React.useState();
  const [cellId, setCellId] = React.useState();
  const [villageId, setVillageId] = React.useState();
  const [provinces, setProvinces] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [cells, setCells] = React.useState([]);
  const [villages, setVillages] = React.useState([]);
  const [value, setFormValue] = React.useState({});
  const [show, setShow] = useState({});

  const setValue0 = (name, val) => {
    if (!name) {
      return null;
    }

    setFormValue((v) => {
      v[name] = val;
      return { ...v };
    });
  };

  const locations = [
    {
      name: "provinceId",
      type: "select",
      value: provinceId,
      values: provinces.map((v) => ({
        label: v.label,
        id: v.value,
      })),
      label: "Province",
      required: true,
      setValue: setProvinceId,
    },
    {
      name: "districtId",
      type: "select",
      required: true,
      label: "District",
      values: districts.map((v) => ({
        label: v.label,
        id: v.value,
      })),
      value: districtId,
      setValue: setDistrictId,
    },
    {
      name: "sectorId",
      type: "select",
      required: true,
      label: "Sector",
      values: sectors.map((v) => ({
        label: v.label,
        id: v.value,
      })),
      value: sectorId,
      setValue: setSectorId,
    },
    {
      name: "cellId",
      type: "select",
      required: true,
      label: "Cell",
      values: cells.map((v) => ({
        label: v.label,
        id: v.value,
      })),
      value: cellId,
      setValue: setCellId,
    },
    {
      name: "village",
      type: "select",
      required: true,
      label: "Village",
      values: villages.map((v) => ({
        label: v.label,
        id: v.value,
      })),
      value: villageId,
      setValue: setVillageId,
    },
  ];
  useEffect(() => {
    let initialFiels = [];
    for (let init = 0; init < mainFields.length; init++) {
      const fields = mainFields[init].fields;
      for (let field = 0; field < fields.length; field++) {
        initialFiels.push(fields[field]);
      }
    }
    setFieldElement(initialFiels);
  }, [mainFields]);

  useEffect(() => {
    watch((value, { name, type }) => {
      if (type === "change") {
        fieldElement.forEach((v) => {
          if (name && name === v.name) {
            setValue0(name, value[name]);
          }
          if (
            name &&
            name === v.name &&
            v.setValue &&
            typeof v.setValue === "function"
          ) {
            v.setValue(value[name]);
          }
        });
        if (locationFields) {
          locations.forEach((v) => {
            if (
              name &&
              name === v.name &&
              v.setValue &&
              typeof v.setValue === "function"
            ) {
              v.setValue(value[name]);
            }
          });
        }
      }
    });
  }, [fieldElement, watch]);

  useEffect(() => {
    fieldElement.forEach((v) => {
      const checkedValue = v.value === null ? null : v.value ?? value[v.name];
      setValue(v.name, checkedValue);

      setValue0(v.name, checkedValue);
    });
  }, [fieldElement]);

  const onFocus = (field) => {
    if (field?.nextType === "date" && field.type !== "date") {
      field.type = "date";
      setFieldElement([...fieldElement]);
    }
  };
  const loadProvinces = async () => {
    const resp = await FetchDetails("admin/dropdowns/provinces");
    if (resp.status) {
      setProvinces(resp.data.data);
    }
  };

  const loadDistricts = async (provinceId) => {
    const resp = await FetchDetails(
      `admin/dropdowns/districts?province=${provinceId}`
    );
    if (resp.status) {
      setDistricts(resp.data.data);
    }
  };

  const loadSectors = async (districtId) => {
    const resp = await FetchDetails(
      `admin/dropdowns/sectors?district=${districtId}`
    );
    if (resp.status) {
      setSectors(resp.data.data);
    }
  };

  const loadCells = async (sectorId) => {
    const resp = await FetchDetails(`admin/dropdowns/cells?sector=${sectorId}`);
    if (resp.status) {
      setCells(resp.data.data);
    }
  };

  const loadVillages = async (cellId) => {
    const resp = await FetchDetails(`admin/dropdowns/villages?cell=${cellId}`);
    if (resp.status) {
      setVillages(resp.data.data);
    }
  };

  useEffect(() => {
    if (locationFields) {
      loadProvinces().then();
    }
  }, [locationFields]);

  useEffect(() => {
    if (provinceId) {
      setValue("districtId", undefined);
      setValue("sectorId", undefined);
      setValue("cellId", undefined);
      setValue("village", undefined);
      loadDistricts(provinceId).then();
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId) {
      setValue("sectorId", undefined);
      setValue("cellId", undefined);
      setValue("village", undefined);
      loadSectors(districtId).then();
    }
  }, [districtId]);

  useEffect(() => {
    if (sectorId) {
      setValue("cellId", undefined);
      setValue("village", undefined);
      loadCells(sectorId).then();
    }
  }, [sectorId]);

  useEffect(() => {
    if (cellId) {
      setVillageId(undefined);
      setValue("village", undefined);
      loadVillages(cellId).then();
    }
  }, [cellId]);


  const onSubmit = async (event) => {

    let newUrl = url;
    const before = beforeSubmit ? await beforeSubmit(event) : true;

    if (!before) {
      return;
    }

    fieldElement?.map((v) => {
      // if (v.setValue && typeof v.setValue === "function") {
      //   if (event[v.name]) {
      //     v.value = value[v.name];
      //   }
      // }
      v.value = value[v.name];
    });

    setLoading(true);
    let formData = undefined;
    const uploadResults = [];

    for (let item of fieldElement) {
      if (item.uploadUrl && typeof item.value !== "string") {
        let form = new FormData();
        if (item.dir) {
          form.append("dir", item.dir);
        }
        if (item.path) {
          form.append("path", item.path);
        }
        let name = item.fileKey ?? item.name;
        if (item.multiple) {
          Array.from(item.value).forEach((file) => {
            form.append(name, file);
          });
        } else {
          form.append(name, item.value);
        }
        const response = await SubmitGeneralForm(item.uploadUrl, form);
        if (response.status) {

          let allowContinue = true;
          if(typeof item.afterUpload === 'function'){
            allowContinue = item.afterUpload(response.data);
          }

          if(!allowContinue){
            setLoading(false);
            return;
          }

          const resp = response.data.data[0];
          item.value = item.uploadJsonResponse ? JSON.stringify(resp) : resp?.path;
          uploadResults.push(resp);
        }
      }
      else if(item.type === 'multi-add' || item.type === 'json-hidden'){
        item.value = typeof item.value === 'string' ? item.value : JSON.stringify(item.value);
      }
    }

    const handleFieldMap = (prev, c) => {
      let { value } = c;

      if (c.submitAsString && value) {
        value = JSON.stringify(value);
      }

      if(typeof c.submitValue === 'function' && value){
        console.log("value",value);
        value = c.submitValue(value);
      }

      if (!c.uploadUrl && (c.type === "file" || c.type === "file-drag")) {
        formData = formData ?? new FormData();
        if (value) {
          if (c.multiple) {
            Array.from(c.value).forEach((file) => {
              if (formData) {
                formData.append(c.name, file);
              }
            });
          } else {
            formData.append(c.name, value);
          }
        }
      }else if(c.urlParam){
        let hasQ = newUrl.includes("?");
        newUrl = `${newUrl}${hasQ ? '&' : '?'}${c.name}=${encodeURIComponent(value)}`;
      } else {
        prev[c.name] = value;
      }
      return prev;
    };

    let map = fieldElement.reduce(handleFieldMap, {});
    if (locationFields) {
      map = locations.reduce(handleFieldMap, map);
    }
    if (formData) {
      formData.append("body", JSON.stringify(map));
    }

    const resp = await SubmitGeneralForm(newUrl, formData ?? map, null, method,responseType);
    if (resp.status) {
      toast.success(customSuccessMessage ?? getSuccessMessage(resp), {});
      handleClose(resp.data);
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(resp.data,uploadResults);
      }
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };
  const handleClose = (data) => {
    let reset = true;
    if (typeof setOpen === "function") {
      reset = setOpen(0,data);
    }
    if(reset !== false) {
      fieldElement?.map((v) => {
        if (v.setValue && typeof v.setValue === "function") {
          v.setValue(null);
        } else {
          setValue(v.name, null);
          setValue0(v.name, null);
        }
      });
      clearErrors();

      setError(null);
    }
  };

  const renderFieldItem = (v) => {
    if (v.hidden) {
      return null;
    }

    const valueSetter = (e) => {
      if (v.setValue && typeof v.setValue === "function") {
        v.setValue(e);
      }else{
        setValue(v.name,e);
      }
      setValue0(v.name, e);
    }


    let requiredRule = {
      required: v.required ? `${v.label} is required !!!` : false,
    };

    if (v.maxDate || v.minDate || typeof v.validate === "function") {
      requiredRule = {
        ...requiredRule,
        validate: (val) => {
          let resp = true;
          if (v.maxDate) {
            resp = moment(val).isSameOrBefore(v.maxDate)
              ? true
              : `Date can't be greater than ${v.maxDate.format("YYYY-MMM-DD")}`;
          }

          if (resp === true && v.minDate) {
            resp = moment(val).isSameOrAfter(v.minDate)
              ? true
              : `Date can't be less than ${v.minDate.format("YYYY-MMM-DD")}`;
          }

          if (typeof v.validate === "function") {
            return v.validate(val, value, v) ?? resp;
          }
          return resp;
        },
      };
    }

    const validationProps = register(v.name, {
      ...requiredRule,
      minLength: v.minLength
        ? {
            value: v.minLength,
            message: `Minimum text length is ${v.minLength?.toLocaleString()}`,
          }
        : undefined,
      maxLength: v.maxLength
        ? {
            value: v.maxLength,
            message: `Maximum text length is ${v.maxLength?.toLocaleString()}`,
          }
        : undefined,
      max: v.hasOwnProperty("max")
        ? {
            value: v.max,
            message: `Maximum number is ${v.max?.toLocaleString()}`,
          }
        : undefined,
      min: v.hasOwnProperty("min")
        ? {
            value: v.min,
            message: `Minimum number is ${v.min?.toLocaleString()}`,
          }
        : undefined,
      pattern:
        v.type === "email"
          ? {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            }
          : v.type === "name"
          ? {
              value: /^[a-zA-Z0-9_ \-=@,.;]+$$/i,
              message: "Only Alphabet,space,number allowed",
            }
          : v.type === "tel"
          ? {
              value: /^(^\+?(?:[+0]9)?[0-9]{8,14}$)$/i,
              message: "Invalid Phone number",
            }
          : null,
    });

    const type = v.type === "email" ? "text" : v.type ?? "text";

    const errorClass = errors[v.name] ? "border border-danger" : "";
    const errorClass2 = errors[v.name]
      ? "select-error text-capitalize"
      : "text-capitalize";
    const className = `${
      v.disabled ? "bg-gray-500 disabled:opacity-75" : ""
    } ${errorClass} form-control`;

    const label = v.label ? (
      <label
        className={`block uppercase fw-semibold ${
          v.type === "switch" ? "" : "mb-2"
        }`}
        htmlFor={v.id}
      >
        {v.label}
        {v.required ? (
          <span className={"text-danger fw-bold fs-6 me-1"}>*</span>
        ) : null}
      </label>
    ) : null;

    if (typeof v.render === "function") {
      return (
        <div>
          {label}
          {v.render()}
        </div>
      );
    }

    return (
      <div
        className={`mb-3 w-full col-md-${v.col ?? col ?? 12} col-lg-${
          v.col ?? col ?? 12
        } col-xl-${v.col ?? col ?? 12}`}
      >
        {v.type === 'multi-select' ? <>
          {label}
          <MultipleSelectCheckmarks values={v.values} value={value[v.name]}  onChange={valueSetter}/>
        </> :v.type === 'multi-select-search' ? <>
          {label}
          <MultiSelectSearch values={v.values} value={value[v.name]}  onChange={valueSetter}/>
        </> : v.type === 'multi-add' ? <>
          {label}
          <MultiAddTextField value={value[v.name]} onChange={valueSetter}/>
        </> : v.type === "switch" ? (
          <div className={"flex items-center"}>
            {label}
            <Controller
              control={control}
              rules={{
                ...requiredRule,
              }}
              name={v.name}
              render={({ field, fieldState: { error } }) => {
                return (
                  <Switch
                    className={error?.message ? "border-danger" : undefined}
                    value={field.value ?? ""}
                    name={v.name}
                    checked={field.value === "1"}
                    onChange={(event, checked) => {
                      // if (v.setValue && typeof v.setValue === "function") {
                        setValue(v.name, `${checked ? 1 : 0}`);
                        field.onChange(`${checked ? 1 : 0}`);
                      // }
                    }}
                  />
                );
              }}
            />
          </div>
        ) : v.type === "editor" ? (
          <div>
            {/* {label} */}
            {label}
            <Controller
              control={control}
              rules={{
                ...requiredRule,
                validate: v.required
                  ? (v) => {
                      if (!v) {
                        return requiredRule.required;
                      }
                      const $ = cheerio.load(v);
                      return $.text()?.trim()?.length
                        ? true
                        : requiredRule.required;
                    }
                  : undefined,
              }}
              name={v.name}
              render={({ field, fieldState: { error } }) => {
                return (
                  <JoditEditor
                    className={error?.message ? "border-red-500" : undefined}
                    value={field.value ?? ""}
                    onBlur={(content)=>{
                      setValue0(v.name,content);

                      setValue(v.name, content);
                    }}
                    onChange={(newContent) => {
                      if (v.setValue && typeof v.setValue === "function") {
                        v.setValue(newContent);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        ) : v.type === "file-drag" ? (
          <div>
            {label}
            {/* {value[v.name] ? label : null} */}
            <FileUploader
              multiple={v.multiple ?? false}
              classes={`${
                errors[v.name] ? "border-red-500" : ""
              } w-full flex-grow-1 max-width-full-i`}
              style={{ maxWidth: "auto !important" }}
              id={v.id}
              handleChange={valueSetter}
              types={v.fileTypes ?? fileTypes}
              {...register(v.name, requiredRule)}
            />
          </div>
        ) : v.type === "checkbox" ? (
          <div>
            <CheckBoxItem item={v} label={label} setValue={valueSetter} value={value[v.name]}/>
          </div>
        ) : v.type === "select" || v.type === "country" ? (
          useMaterial && v.type === "select" ? (
            <FormControl fullWidth size={"small"}>
              <InputLabel id="demo-simple-select-label">
                {v.label ?? v.name}
              </InputLabel>
              <Select
                native={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={v.value}
                label={v.label}
                {...register(v.name, requiredRule)}
              >
                <option value=""></option>
                {v.values?.map((item) => (
                  <option value={item.id}>{item.label ?? item.name}</option>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div className="relative w-full">
              {label}
              {/* {value[v.name] ? label : null} */}
              <Controller
                control={control}
                rules={{
                  ...requiredRule,
                }}
                name={v.name}
                render={({ field, fieldState: { error } }) => {
                  let selectedValue =
                    v?.values?.find((item) => item.id === field.value) ?? null;
                  selectedValue = selectedValue
                    ? {
                        id: selectedValue.id,
                        label: selectedValue?.label ?? selectedValue?.name,
                      }
                    : null;
                  return (
                    <div className="relative w-full">
                      {v.type === "country" ? (
                        <CountrySelector
                          // placeholder={v.placeholder ?? v.label}
                          className={errorClass2}
                          value={field.value}
                          onChange={(v) => field.onChange(v?.id)}
                          name={v.name}
                        />
                      ) : (
                        <ReactSelect
                          // placeholder={v.placeholder ?? v.label}
                          menuPortalTarget={document.body}
                          isDisabled={v.disabled ?? false}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          className={errorClass2}
                          value={!field.value ? null : selectedValue}
                          options={v.values?.map(({ label, id, name }) => ({
                            id,
                            label: label ?? name,
                          }))}
                          onChange={(v) => field.onChange(v?.id)}
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          )
        ) : useMaterial ? (
          <TextField
            autoFocus
            id={v.id}
            label={v.label}
            minRows={v.type === "textarea" ? 5 : undefined}
            type={type}
            fullWidth
            InputLabelProps={
              v.type === "date" ||
              v.type === "file" ||
              v.type === "datetime-local"
                ? {
                    shrink: true,
                  }
                : {}
            }
            size={"small"}
            variant="outlined"
            {...validationProps}
          />
        ) : (
          <div className="relative w-full">
            {label}
            {/* {value[v.name] ? label : <label></label>} */}

            {v.type === "textarea" ? (
              <textarea
                rows={v.rows ?? 3}
                // placeholder={v.placeholder ?? v.label}
                className={className}
                {...validationProps}
              ></textarea>
            ) : (type === 'password' ? <div  className={'position-relative'}>
                      <div className={'position-absolute top-0 py-2 px-3'} onClick={()=>setShow(show=>({...show,[v.name] : !show[v.name]}))} style={{right:0}}>
                        <i className={show[v.name] ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                      </div>
                      <input
                          onFocus={() => {
                            onFocus(v);
                          }}
                          className={className}
                          disabled={v.disabled ?? false} type={show[v.name] ? 'text' : "password"} {...validationProps}/>
                    </div> :
              <input
                type={type === "name" ? "text" : (type === 'check' ? 'checkbox' : type)}
                // placeholder={`${v.placeholder ?? v.label}${
                //   v.required ? "*" : ""
                // }`}
                min={v.minDate?.format("YYYY-MM-DD")}
                max={v.maxDate?.format("YYYY-MM-DD")}
                multiple={v.multiple ?? false}
                className={type === 'check' ? null : className}
                onFocus={() => {
                  onFocus(v);
                }}
                disabled={v.disabled ?? false}
                {...validationProps}
              />
            )}
          </div>
        )}

        {errors[v.name] && (
          <div role="alert" className={"text-danger text-xs"}>
            {errors[v.name]?.message?.toString()}
          </div>
        )}
      </div>
    );
  };

  const dialogContent = (
    <>
      {formAlert ? (
        <Alert
          style={{ width: "100%" }}
          className={"mb-3"}
          severity={"warning"}
        >
          {formAlert}
        </Alert>
      ) : null}

      {error ? (
        <Alert
          style={{ width: "100%" }}
          className={"mb-3"}
          severity={"error"}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      ) : null}
      {/*<DialogContentText>*/}
      {/*</DialogContentText>*/}
      <div className={"row"}>
        {mainFields?.map((item) => (
          <>
            <Typography className="fw-bold text-capitalize mt-3 mb-2">
              {item.title}
            </Typography>
            {item?.fields?.map(renderFieldItem)}
          </>
        ))}

        {locationFields ? <>{locations.map(renderFieldItem)}</> : null}
        {appendForm ? appendForm : null}
      </div>
      {footer}
    </>
  );

  const loadingWidget = loading ? (
    <div
      className={
        "position-absolute h-100 w-100 top-0 start-0 d-flex justify-content-center align-items-center"
      }
      style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: 50 }}
    >
      <div
        className={"bg-white p-3 rounded shadow text-center"}
        style={{ zIndex: 20000 }}
      >
        <div>
          <CircularProgress color={"success"} />
        </div>
        <span>
          <Typography>Submitting ....</Typography>
        </span>
      </div>
    </div>
  ) : null;

  const dialogWidget = inline ? (
    dialogContent
  ) : (
    <DialogContent className={"overflow-visible"}>
      {dialogContent}
    </DialogContent>
  );
  const buttons = hideSubmitButton ? null : (
    <>
      <button
        type={"button"}
        onClick={()=> handleClose()}
        className={"btn btn-outline-danger me-1"}
      >
        <i className={"bi bi-x-lg"}></i> {cancelLabel ?? 'Cancel'}
      </button>
      {loading ? (
        <span>
          <CircularProgress />
        </span>
      ) : (
        <button type={"submit"} className={"btn btn-primary"}>
          <i className={"bi bi-send-fill"}></i> {buttonTitle ?? 'Submit'}
        </button>
      )}
    </>
  );

  const content = (
    <>
      <Card className={`${!elevation ? 'shadow-none' : 'shadow-sm'} position-relative`}>
        <div className={"card-body"}>
          <h4>{title}</h4>
          {loadingWidget}
          {dialogWidget}
          <div className={"clearfix mt-3"}>
            <div className={"float-end d-flex"}>{buttons}</div>
          </div>
        </div>
      </Card>
    </>
  );

  return (
    <div>
      {inline ? (
        <form action="/x" onSubmit={handleSubmit(onSubmit)}>
          <div className={"relative"}>{content}</div>
        </form>
      ) : (
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth ?? "sm"}
          open={open !== undefined && open > 0}
          onClose={handleClose}
          className={"p-0"}
        >
          <form action="/x" onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{title}</DialogTitle>
            {loadingWidget}
            {dialogWidget}
            <DialogActions>{buttons}</DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(AddForm);
