import {useEffect, useState} from "react";
import {Chip} from "@mui/material";

const MultiAddTextField = (props)=>{
    const {value,onChange} = props;

    const [list,setList] = useState([]);
    const [fieldValue,setFieldValue] = useState('');


    useEffect(()=>{
        if(value){
            if(typeof value === 'string'){
                setList(JSON.parse(value));
            }else if(Array.isArray(value)){
                setList([...value]);
            }
        }
    },[value]);

    function runCallback(newList) {
        if(onChange && typeof onChange === 'function'){
            onChange(newList);
            setList(newList);
        }
    }

    const addValue = ()=>{
        if(fieldValue.trim()) {
            runCallback([...list, fieldValue]);
            setFieldValue('');
        }
    }

    return <div>
        <div className="input-group">
            <input type="text" className="form-control" placeholder={"Press Enter to Add"} value={fieldValue} onChange={event=>{
                setFieldValue(event.currentTarget.value);
            }} onKeyDown={(e)=>{
                if(e.key === "Enter") {
                    e.preventDefault();
                    addValue();
                }
            }}/>
                <button onClick={addValue} className="btn btn-outline-secondary" type="button" id="button-addon2">Add</button>
        </div>
        {list.length > 0 && <div className={'mt-2'}>
            {
                list.map((v, index) => <Chip label={v} className={'me-2'} size={'small'} onDelete={() => {
                    list.splice(index, 1)
                    runCallback(
                        [...list]
                    )
                }}/>)
            }
        </div>}
    </div>
}

export default MultiAddTextField;
