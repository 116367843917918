import {connect} from 'react-redux';
import {mapStateToProps} from "../../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {Box, Card, Tabs, Tab} from "@mui/material";
import {makeStyles} from '@mui/styles';
import a11yProps from "../../../../helpers/ay-props";
import TabPanel from "../../../../helpers/TabPanel";
import {useState} from "react";
import LawReportDocuments from "./law-report-documents";
import CourtLegalPositionDocuments from "./court-legal-position-documents";
import {useSearchParams} from "react-router-dom";
import ScholarlyMaterialsDocuments from "./scholarly-materials-documents";
import {inArray} from "../../../../store/helpers/common_functions";

const useStyles = makeStyles({
    tabs: {

        "& .MuiTabs-indicator": {
            backgroundColor: "#597964",
            height: 3,
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#597964'
        }
    }
});

const ResourceDocuments = ({loggedUser})=>{
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get("tab");


    const hasLawReport = inArray(loggedUser?.permissions,"sections-view-221");
    const hasCourtPrecedence = inArray(loggedUser?.permissions,"sections-view-222");
    const hasScholar = inArray(loggedUser?.permissions,"sections-view-223");

    const init = hasLawReport ? 0 : hasCourtPrecedence ? 1 : hasScholar ? 2 : 0;

    const [tabValue, setTabValue] = useState( tab ? Number(tab) : init);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setSearchParams(`?${new URLSearchParams({ tab: newValue })}`)
    };



    const classes = useStyles();


    return <div>
        <Card>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs indicatorColor={'success'}
                      className={classes.tabs} value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    {hasLawReport && <Tab label="Rwanda law reports" {...a11yProps(0)} />}
                    {hasCourtPrecedence && <Tab label="Court Precedence" {...a11yProps(1)} />}
                    {hasScholar && <Tab label="Scholarly materials" {...a11yProps(2)} />}
                </Tabs>
            </Box>
            {hasLawReport && <TabPanel index={0} value={tabValue} p={0}>
                <LawReportDocuments/>
            </TabPanel>}
            {hasCourtPrecedence && <TabPanel index={1} value={tabValue} p={0}>
                <CourtLegalPositionDocuments/>
            </TabPanel>}
            {hasScholar && <TabPanel index={2} value={tabValue} p={0}>
                <ScholarlyMaterialsDocuments/>
            </TabPanel>}
        </Card>
    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(ResourceDocuments);