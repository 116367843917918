
import React, {useEffect} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, Block, BorderColor, VerifiedUser} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
const Cases = (props) => {
    const {FetchDetails} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }





    return <>
        <AddForm setOpen={handleClose} method={id ? 'PUT' : 'POST'} title={id ? 'Update Case' : 'Create New Case'} url={id ? `cases/update/${id}` : 'cases/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"no",
                        label:"No",
                        required:true,
                        value:info?.no,
                    },
                    {
                        name:"name",
                        label:"Name",
                        required:true,
                        value:info?.name,
                    },
                    {
                        name:"description",
                        label:"Description",
                        required:true,
                        type:'textarea',
                        value:info?.description,
                    },
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DatatableComponent deleteUrl={(id,info)=>({
            url:`cases/action/single`,
            post:true,
            icon:()=>{
                return info?.status === 0 ? <VerifiedUser /> : <Block/>
            },
            color:info?.status === 0 ? 'success' : undefined,
            title:info?.status === 0 ? 'activate' : 'deactivate',
            body:{
                id,
                action:info?.status === 0 ? "active" : "block"
            }
        })} addRowNumber={true} onAddNew={()=>{
            setOpen(1);
        }} actions={(id,meta,info)=>[
            <IconButton onClick={() => {
                setOpen(2);
                setId(id);
                setInfo(info);
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"No",
                name:"no"
            },
            {
                label:"Name",
                name:"name"
            },
            {
                label:"Description",
                name:"description"
            },
            {
                label:"Status",
                name:"status",
                options: {
                    customBodyRender:(v)=><Chip size={'small'} color={v=== 0 ? 'error' : 'success'} label={v === 0 ? 'Disabled' : 'Active'}/>
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Cases'} url={`cases/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(Cases);
