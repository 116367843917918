
import React, {useEffect} from 'react'
import DatatableComponent from "../../../helpers/DatatableComponent";
import moment from "moment";
import AddForm from "../../../helpers/AddForm";
import {Accessibility, Block, BorderColor, VerifiedUser} from "@mui/icons-material";
import {Chip, IconButton} from "@mui/material";
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {inArray} from "../../../store/helpers/common_functions";
const SubCategories = (props) => {
    const {FetchDetails,loggedUser} = props;
    const [open,setOpen] = React.useState(0);
    const [change,setChange] = React.useState(0);
    const [categories,setCategories] = React.useState([]);
    const [id,setId] = React.useState();
    const [info,setInfo] = React.useState();
    const handleClose = ()=>{
        setOpen(0);
        setId(null);
        setInfo(null);
    }

    const loadCategories = async ()=>{
        const resp = await FetchDetails("dropdowns/categories");
        if(resp.status){
            setCategories(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }

    useEffect(()=>{
        loadCategories().then();
    },[]);



    return <>
        <AddForm setOpen={handleClose} method={id ? 'PUT' : 'POST'} title={id ? 'Update Sub Category' : 'Create New Sub Category'} url={id ? `sub-categories/update/${id}` : 'sub-categories/create'} open={open ===2 || open === 1} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"name_en",
                        label:"Name(English)",
                        required:true,
                        value:info?.name_en,
                    },
                    {
                        name:"name_rw",
                        label:"Name (Kinyarwanda)",
                        value:info?.name_rw,
                        required:true
                    },
                    {
                        name:"name_fr",
                        label: "Name (French)",
                        value:info?.name_fr,
                        required:true
                    },
                    {
                        name:"category",
                        label: "Select Category",
                        value:info?.category?.id,
                        values:categories,
                        type:'select',
                        required:true
                    },
                    {
                        name:"code",
                        label:"Code",
                        value:info?.code,
                        required:true
                    }
                ]
            }
        ]} onSuccess={()=> {
            setChange(c => c + 1);
            handleClose();
        }}/>
        <DatatableComponent forceDeleteUrl={(id )=>({
            url:`sub-categories/action/single`,
            post:true,
            body:{
                id,
                action:"delete"
            }
        })} deleteUrl={inArray(loggedUser?.permissions,"sub-categories-delete") ? (id,info)=>({
            url:`sub-categories/action/single`,
            post:true,
            icon:()=>{
                return info?.status === 0 ? <VerifiedUser /> : <Block/>
            },
            color:info?.status === 0 ? 'success' : undefined,
            title:info?.status === 0 ? 'activate' : 'deactivate',
            body:{
                id,
                action:info?.status === 0 ? "active" : "block"
            }
        }) : undefined} addRowNumber={true} onAddNew={inArray(loggedUser?.permissions,"sub-categories-create") ? ()=>{
            setOpen(1);
        } : undefined} actions={(id, _meta,info)=>[
            inArray(loggedUser?.permissions,"sub-categories-update") && <IconButton onClick={() => {
                setOpen(2);
                setId(id);
                setInfo(info);
            }}>
                <BorderColor />
            </IconButton>
        ]} elevation={1} columns={[
            {
                label:"Category",
                name:"category.name_en"
            },
            {
                label:"Name (English)",
                name:"name_en"
            },
            {
                label:"Name (Kinyarwanda)",
                name:"name_rw"
            },
            {
                label:"Name (French)",
                name:"name_fr"
            },
            {
                label:"Code",
                name:"code"
            },
            {
                label:"Status",
                name:"status",
                options: {
                    customBodyRender:(v)=><Chip size={'small'} color={v=== 0 ? 'error' : 'success'} label={v === 0 ? 'Disabled' : 'Active'}/>
                }
            },
            {
                label:"Created At",
                name:"created_at",
                options:{
                    customBodyRender:(v)=>moment(v).format("YYYY-MMM-DD")
                }
            }
        ]} title={'Sub Categories'} url={`sub-categories/table?change=${change}`}/>
    </>;
}
export default connect(mapStateToProps,mapDispatchToProps)(SubCategories);
