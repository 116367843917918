import {Button, CircularProgress, IconButton} from "@mui/material";
import {BorderColor, CancelOutlined, CheckCircleRounded, Send} from "@mui/icons-material";
import {useState} from "react";
import Swal from "sweetalert2";
import {inArray} from "../../store/helpers/common_functions";
import PublishApplication from "./publish_application";
import {useLocation, useNavigate} from "react-router-dom";

const SubmitApplication = (props) => {

    const {size,info,SubmitGeneralForm,onSuccess,loggedUser} = props;
    const navigate = useNavigate();

    const canSubmit = inArray([0,3],info?.status) && inArray(loggedUser?.permissions,'documents-submit');
    const canApprove = info?.status === 1 && inArray(loggedUser?.permissions,'documents-review');

    const [loading, setLoading] = useState(false);

    const onSubmit = async (reject,comments = null)=>{
        setLoading(true);
        const resp = await SubmitGeneralForm("documents/action/single",{
         id:info?.id,
         action: reject ? "reject" : canApprove ? "approve" : "submit",
            comments,
        });
        if(resp.status && typeof onSuccess === 'function'){
            onSuccess(resp.data);
        }
        setLoading(false);
    }

    const onClick = (reject)=>{
        reject = reject === true;
        Swal.fire({
            title: reject ? "Request action for Reviewed document" : (canApprove ? "Approve reviewed document" : "Submit Document for review!"),
            text: reject ? "Are you sure you want to request action this reviewed document ?" : (canApprove ? "Are you sure you want to approve this reviewed document ?" : "Are you sure you want to submit this documents for review ?"),
            icon: reject ? 'error' : 'success',
            showConfirmButton:true,
            showCancelButton:true,
            // input: reject || canApprove ? "textarea" : undefined,
            input: "textarea",
            inputAttributes:{rows:2},
            inputValidator(inputValue, validationMessage) {
                return !inputValue?.trim() && reject ? "Comment is required while requesting for action on this document" : null;
            },
            inputPlaceholder:"Add Comment here",
            confirmButtonColor: reject ? '#d32f2f' : (canApprove ? '#2e7d32' : '#1976d2'),
            confirmButtonText: reject ? "Request for Action" : (canApprove ? "Approve" : "Submit"),
        }).then((info)=>{

            // console.log(info.value);
            if(info.isConfirmed){
                onSubmit(reject,info.value??null).then();
            }
        });
    }

    const icon = loading ? <CircularProgress size={size === 'small' ? 20 : undefined}/> : (canApprove ? <CheckCircleRounded/> : <Send/>);
    const rejectIcon = loading ? <CircularProgress size={size === 'small' ? 20 : undefined}/> : <CancelOutlined/>;

    const color = canApprove ? 'success' : 'success';

    const location = useLocation();

    const updateButton = location.pathname.indexOf("details") > -1 && <Button onClick={()=>navigate(location.pathname.replace("details","update"))} size={'large'} className={'me-2'} variant={'contained'} color={color} disabled={loading} startIcon={<BorderColor/>}>Edit
        Document</Button>;


  return info?.status === 2 ? <div className={'text-center'}>
      {info?.status_publish === 3 && updateButton}
      <PublishApplication {...props}/>
  </div>  : (canSubmit || canApprove) && <>
      {size === 'small' ? <>
          <IconButton color={color} disabled={loading} onClick={onClick}>
              {icon}
          </IconButton>
          {canApprove && <IconButton color={'error'} disabled={loading} onClick={onClick}>
              {rejectIcon}
          </IconButton>}
      </> : <div className={'text-center mt-2'}>

          {inArray([0,1,3],info?.status) && updateButton}
          <Button size={'large'} variant={'contained'} color={color} disabled={loading} onClick={onClick} startIcon={icon}>{canApprove ? 'Approve Document' : `${info?.status === 3 ? 'Re-':''}Submit Document`}</Button>
          {canApprove && <Button className={'ms-2'} size={'large'} variant={'contained'} color={'error'} disabled={loading} onClick={()=>onClick(true)} startIcon={rejectIcon}>Request For Action</Button>}
      </div>}
  </>;
}

export default SubmitApplication;