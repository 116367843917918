
import {connect} from 'react-redux';
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import AddForm from "../../../helpers/AddForm";
import { saveAs } from 'file-saver';
import {Button, Row} from "react-bootstrap";
import React, {useState} from "react";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {blobToBase64} from "../../../store/helpers/common_functions";

const PdfCrop = () => {
    const [base64, setBase64] = useState(null);
    const [blob, setBlob] = useState(null);
    const [sWidth, setSWidth] = useState(-80);
    const [sHeight, setSHeight] = useState(-120);
    const [tWidth, setTWidth] = useState(-45);
    const [tHeight, setTHeight] = useState(-65);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return <Row className={'justify-content-center'}>
        <div className={'col-md-5'}>
            <AddForm responseType={'blob'} onSuccess={async (data)=>{
                const file = new Blob([data], {
                    type: "application/pdf",
                });

                setBlob(file);

                setBase64(await blobToBase64(file));
            }} setOpen={()=>false} url={'pdf/crop'} inline={true} title={"Crop Pdf Document"} col={6} mainFields={[
                {
                    title:"",
                    fields:[
                        {
                            col:12,
                            name: "file1",
                            label: "Upload PDF Document",
                            type: 'file-drag',
                            required: true,
                            fileTypes:["PDF"],
                        },
                        {
                            name:"sWidth",
                            value:sWidth,
                            urlParam:true,
                            label:"Right",
                            setValue:setSWidth
                        },
                        {
                            name:"sHeight",
                            value:sHeight,
                            urlParam:true,
                            label:"Top",
                            setValue:setSHeight
                        },
                        {
                            name:"tWidth",
                            value:tWidth,
                            urlParam:true,
                            label:"Left",
                            setValue:setTWidth
                        },
                        {
                            name:"tHeight",
                            label:"Bottom",
                            value:tHeight,
                            urlParam:true,
                            setValue:setTHeight,
                        },
                    ]
                }
            ]}/>
        </div>
        {base64 !== null?
            <div className={'col-md-7'}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={base64}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance,
                            ]}/>
                </Worker>
                <div className={'mt-2 text-center'}>
                    <Button onClick={()=>{
                        saveAs(blob,"cropped-document.pdf");
                    }}>Download</Button>
                </div>
            </div> : null}
    </Row>
}

export default connect(mapStateToProps,mapDispatchToProps)(PdfCrop);