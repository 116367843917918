import React, {memo,Fragment} from 'react'
import { Row,Col,Container} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
//img
import topHeader from '../../../../assets/images/dashboard/top-header.png'
import topHeader1 from '../../../../assets/images/dashboard/top-header1.png'
import topHeader2 from '../../../../assets/images/dashboard/green_bg.png'
import topHeader3 from '../../../../assets/images/dashboard/top-header3.png'
import topHeader4 from '../../../../assets/images/dashboard/top-header4.png'
import topHeader5 from '../../../../assets/images/dashboard/top-header5.png'
import {IconButton} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";


const SubHeader = memo((props) => {

    const navigate = useNavigate();
    const location = useLocation();
    return (
        <Fragment>
            <div className="iq-navbar-header" style={{height: "115px"}}>
                <Container fluid className="text-white py-3" style={{paddingLeft:"38px"}}>
                    <Row>
                        <Col md="12">
                            <div className="d-flex flex-wrap">
                                {location.key !== 'default' && location.pathname !== '/dashboard' && <IconButton onClick={()=>navigate(-1)}>
                                    <ArrowBack color={'inherit'} style={{color:"white"}}/>
                                </IconButton>}
                                <div>
                                    <h2 className={'text-white'}>Amategeko Admin Panel</h2>
                                </div>
                                <div className="d-flex align-items-center">
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* {{!-- rounded-bottom if not using animation --}} */}
                <div className="iq-header-img">
                    <img src={topHeader2} alt="header" style={{filter: 'invert(50%) sepia(12%) saturate(704%) hue-rotate(88deg) brightness(95%) contrast(89%);'}} className="theme-color-default-img img-fluid w-100 h-100 animated-scaleX"/>
                    <img src={topHeader1} alt="header" className=" theme-color-purple-img img-fluid w-100 h-100 animated-scaleX"/>
                    <img src={topHeader} alt="header" className="theme-color-blue-img img-fluid w-100 h-100 animated-scaleX"/>
                    <img src={topHeader3} alt="header" className="theme-color-green-img img-fluid w-100 h-100 animated-scaleX"/>
                    <img src={topHeader4} alt="header" className="theme-color-yellow-img img-fluid w-100 h-100 animated-scaleX"/>
                    <img src={topHeader5} alt="header" className="theme-color-pink-img img-fluid w-100 h-100 animated-scaleX"/>
                </div>
            </div>
        </Fragment>
    )
})

export default SubHeader
