import OCRScan from "../ocr_scan";
import React, {useEffect} from "react";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {Col, FormGroup, Row} from "react-bootstrap";
import Status from "../../../../components/partials/status";
import PublishStatus from "../../../../components/partials/publish_status";

const DecisionCreate = ({FetchDetails}) => {
    const [info,setInfo] = React.useState();
    const [collections,setCollections] = React.useState([]);
    const [collection,setCollection] = React.useState(null);
    // const [cases,setCases] = React.useState([]);
    const [judges,setJudges] = React.useState([]);
    const [relations,setRelations] = React.useState([]);
    const [decisionTypes,setDecisionTypes] = React.useState([]);
    const [decisionStatuses,setDecisionStatuses] = React.useState([]);
    const [loadingPage,setLoadingPage] = React.useState(false);
    // const [categories,setCategories] = React.useState([]);
    // const [subCategories,setSubCategories] = React.useState([]);
    const [categoryId,setCategoryId] = React.useState();

    const {id} = useParams();

    const section = "2.1";





    const loadCollections = async ()=>{
        const resp = await FetchDetails("dropdowns/collections");
        if(resp.status){
            setCollections(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }
    // const loadSubCategories = async ()=>{
    //     const resp = await FetchDetails(`dropdowns/sub-categories?category=${categoryId}`);
    //     if(resp.status){
    //         setSubCategories(resp.data.data?.map(v=>({...v,id:v.value})));
    //     }
    // }

    // const loadCategories = async ()=>{
    //     const resp = await FetchDetails("dropdowns/categories");
    //     if(resp.status){
    //         setCategories(resp.data.data?.map(v=>({...v,id:v.value})));
    //     }
    // }
    // const loadCases = async ()=>{
    //     const resp = await FetchDetails("dropdowns/cases");
    //     if(resp.status){
    //         setCases(resp.data.data?.map(v=>({...v,id:v.value})));
    //     }
    // }
    const loadJudges = async (collection)=>{
        const resp = await FetchDetails(`dropdowns/judges?collection=${collection}`);
        if(resp.status){
            setJudges(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }
    const loadRelations = async ()=>{
        const resp = await FetchDetails("dropdowns/relations");
        if(resp.status){
            setRelations(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }
    const loadDecisionTypes = async ()=>{
        const resp = await FetchDetails("dropdowns/decisions-types");
        if(resp.status){
            setDecisionTypes(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }
    const loadDecisionStatuses = async ()=>{
        const resp = await FetchDetails("dropdowns/decisions-status");
        if(resp.status){
            setDecisionStatuses(resp.data.data?.map(v=>({...v,id:v.value})));
        }
    }

    const loadInfo = async (id)=>{
        setLoadingPage(true);
        const resp = await FetchDetails(`documents/${id}`);
        setLoadingPage(false);
        if(resp.status){
            const data = resp.data?.data;
            setCategoryId(data?.category?.id);
            setInfo({...data,judges:data.judges?.split(",")?.map(v=>Number(v))});
            setCollection(data?.collection?.id ?? data?.collection);
        }
    }

    useEffect(()=>{
        loadCollections().then();
        loadRelations().then();
        loadDecisionTypes().then();
        loadDecisionStatuses().then();
    },[]);
    useEffect(()=>{
        if(collection){
            loadJudges(collection).then();
        }
    },[collection]);



    useEffect(()=>{
        if(id){
            loadInfo(id).then();
        }
    },[id]);


    const preSetInfo = (info2)=>{
        if(info2?.id){
            loadInfo(info2.id).then();
        }
    }

  return <OCRScan section={section} info={info} setInfo={(info)=>{
      setCategoryId(info?.category?.id ?? info?.category);
      preSetInfo(info);
  }} id={id} loadingPage={loadingPage} fields={[
      {
          name: "name",
          label: "Case name",
          value: info?.name,
          type: 'text',
          required: true
      },
      {
          name: "case_no",
          label: "Case number",
          value: info?.case_no,
          type: 'text',
          required: true
      },
      {
          name: "section",
          label: "Section",
          hidden: true,
          value:section
      },
      {
          name: "date",
          label: "Date",
          value: info?.date,
          type: 'date',
          required: true
      },
      {
          name: "collection",
          label: "Select Collection",
          value: collection,
          values: collections,
          type: 'select',
          setValue:setCollection,
          required: true
      },
      {
          name: "neutral_citation",
          label: "Neutral citation",
          value: info?.neutral_citation,
          type: 'text'
      },
      {
          name: "report_citation",
          label: "Report citation",
          value: info?.report_citation,
          type: 'text'
      },
      // {
      //     name: "parties",
      //     label: "Parties",
      //     value: info?.parties,
      //     type: 'text',
      //     required: true
      // },
      // {
      //     name: "statuses",
      //     label: "Statuses",
      //     value: info?.statuses,
      //     type: 'text',
      //     required: true
      // },
      // {
      //     name: "cases",
      //     label: "Select cases",
      //     value: info?.cases,
      //     values: cases,
      //     type: 'multi-select',
      //     required: true
      // },
      {
          name: "judges",
          label: "Select judges",
          value: info?.judges,
          values: judges,
          type: 'multi-select-search',
          required: true
      },
      {
          name: "relations",
          label: "Select Relation",
          value: info?.relations?.id ?? info?.relations,
          values: relations,
          type: 'select'
      },
      {
          name: "decisionsTypes",
          label: "Select Decision Type",
          value: info?.decisionsTypes?.id ?? info?.decisionsTypes,
          values: decisionTypes,
          type: 'select',
          required: true
      },
      {
          name: "decisionsStatus",
          label: "Select Status",
          value: info?.decisionsStatus?.id ?? info?.decisionsStatus,
          values: decisionStatuses,
          type: 'select',
          required: true
      },
  ]} formTitle={'Decision'} details={
      <Row>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Case name</span>
                  <div>{info?.name}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Case number</span>
                  <div>{info?.case_no}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Date</span>
                  <div>{info?.date}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Collection</span>
                  <div>{info?.collection?.name_en}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Neutral citation</span>
                  <div>{info?.neutral_citation}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Reported Citation</span>
                  <div>{info?.report_citation}</div>
              </FormGroup>
          </Col>
          {/*<Col md={4}>*/}
          {/*    <FormGroup className={'mb-3'}>*/}
          {/*        <span className={'fw-bold'}>Parties</span>*/}
          {/*        <div>{info?.parties}</div>*/}
          {/*    </FormGroup>*/}
          {/*</Col>*/}
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Relation</span>
                  <div>{info?.relations?.name}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Decision Type</span>
                  <div>{info?.decisionsTypes?.name_en}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Decision Status</span>
                  <div>{info?.decisionsStatus?.name_en}</div>
              </FormGroup>
          </Col>
          <Col md={4}>
              <FormGroup className={'mb-3'}>
                  <span className={'fw-bold'}>Status</span>
                  <div><Status status={info?.status} info={info}/></div>
              </FormGroup>
          </Col>
      </Row>
  }/>
}

export default connect(null,mapDispatchToProps)(DecisionCreate);