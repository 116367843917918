import React, {useState} from 'react'
import {Row, Col, Form, Button, Alert,} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import logo from '../../../assets/images/auth/logo.png';

import auth1 from '../../../assets/images/auth/map.png'
import {connect} from "react-redux";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import {CircularProgress} from "@mui/material";
import PasswordField from "../../../components/password_field";

const SignIn = (props) => {
   const {SystemLogin,errorMessage,successMessage} = props;

   const [loading,setLoading] = useState(false);

   // let history = useNavigate()
   return (
      <>
         <Card className="shadow-none mb-0 py-4 card-bg">
            <Card.Body>
               <Link to="/dashboard" className="navbar-brand d-flex align-items-center mb-3">
               </Link>
               {errorMessage || successMessage ? (
                   <Alert variant={`${successMessage ? 'success' : 'danger'} d-flex align-items-center`} role="alert">
                      <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                         <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                      </svg>
                      <div>
                         {successMessage ?? errorMessage}
                      </div>
                   </Alert>
               ) : null}
               <Form action={""} method={"POST"} onSubmit={async (event) => {
                  event.preventDefault();
                  if(loading) return;

                  setLoading(true);
                  const data = new FormData(event.currentTarget);
                  await SystemLogin({
                     email:data.get("email"),
                     password:data.get("password")
                  });

                  setLoading(false);
               }}>
                  <Row>
                     <Col md="12">
                        <Form.Group className="form-group position-relative">
                           <div className={'position-absolute top-0 py-2 px-3'} style={{left:0}}>
                              <i className={'fa fa-user'}></i>
                           </div>
                           <Form.Control type="email" className="rounded-0 ps-5" placeholder={"Email"} name={'email'} id="email" aria-describedby="email" required={true}/>
                        </Form.Group >
                     </Col>
                     <Col lg="12" className="mt-3">
                        <Form.Group className="form-group">
                           <PasswordField name={'password'} id={'password'} placeholder={"Password"} className={'form-control rounded-0'}/>
                        </Form.Group>

                     </Col>
                     <Col lg="12" className="d-flex justify-content-between mt-2">
                        <Form.Check className="form-check mb-3">
                           <Form.Check.Input type="checkbox" id="customCheck1" />
                           <Form.Check.Label htmlFor="customCheck1">Keep me logged-in</Form.Check.Label>
                        </Form.Check>
                        <Link to="/auth/recoverpw" style={{color:'#2e86ee'}}>Forgot Password?</Link>
                     </Col>
                  </Row>
                  <div className="mt-3">
                     <Button type="submit" variant="btn btn-primary" className="w-100" disabled={loading}>{loading && <CircularProgress size={20} color={'error'}/>} Sign In</Button>
                  </div>
                  {/*<p className="text-center my-3">or sign in with other accounts?</p>*/}
                  {/*<div className="d-flex justify-content-center">*/}
                  {/*   <ListGroup as="ul" className="list-group-horizontal list-group-flush">*/}
                  {/*      <ListGroup.Item as="li" className="border-0 pb-0">*/}
                  {/*         <Link to="#"><Image src={facebook} alt="fb" /></Link>*/}
                  {/*      </ListGroup.Item>*/}
                  {/*      <ListGroup.Item as="li" className="border-0 pb-0">*/}
                  {/*         <Link to="#"><Image src={google} alt="gm" /></Link>*/}
                  {/*      </ListGroup.Item>*/}
                  {/*      <ListGroup.Item as="li" className="border-0 pb-0">*/}
                  {/*         <Link to="#"><Image src={instagram} alt="im" /></Link>*/}
                  {/*      </ListGroup.Item>*/}
                  {/*      <ListGroup.Item as="li" className="border-0 pb-0">*/}
                  {/*         <Link to="#"><Image src={linkedin} alt="li" /></Link>*/}
                  {/*      </ListGroup.Item>*/}
                  {/*   </ListGroup>*/}
                  {/*</div>*/}
                  {/*<p className="mt-3 text-center">*/}
                  {/*   Don’t have an account? <Link to="/auth/sign-up" className="text-underline">Click here to sign up.</Link>*/}
                  {/*</p>*/}
               </Form>
            </Card.Body>
         </Card>

      </>
   )
}

export default connect(mapStateToProps,mapDispatchToProps)(SignIn)
